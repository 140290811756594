import { Button } from "@/components/ui/button"

import logo from "@/assets/svg/logo-whitebg.svg"
import { cn, getContrastColorFromBackgroundColor } from "@/lib/utils"
import { Mic, MicOff, User2Icon, Video, VideoOff } from "lucide-react"
import { useEffect, useRef, useState } from "react"


import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

import useDims from "@/lib/useDims"
import { InterviewQuery, useUpdateInterviewConfirmationMutation } from "@/fetchers/useInterview"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { SettingsDialog } from "./SettingsDialog"
import { useIsMutating } from "@tanstack/react-query"
import { MIME_TYPE, UploadInput } from "../common/UploadInput"
import { useParams } from "@tanstack/react-router"

type TProps = {
  onClickStart: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webcamManager: any,
  data?: InterviewQuery,
  noMic?: boolean | string,
}
export function PreInterviewComponent({ onClickStart, webcamManager, data, noMic }: TProps) {
  const [litBars, setLitBars] = useState(0);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const requestRef = useRef();
  const [micOn, setMicOn] = useState(true);
  const isMutating = useIsMutating()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [hasResume, setHasResume] = useState<any>(true)
  const update = useUpdateInterviewConfirmationMutation()
  // @ts-ignore
  const { interviewId: interviewUid } = useParams({ strict: false })
  // const username = details?.firstName && details?.lastName ? `${details?.firstName || ""} ${details?.lastName || ""}` : (details?.email || "Candidate")
  // const intials = details?.firstName && details?.lastName ? `${details?.firstName?.charAt(0) || ""}${details?.lastName?.charAt(0) || ""}` : (`${details?.email?.charAt(0) || ""}` || "C")
  const username = data?.userName || "Candidate"
  const intials = data?.userName?.charAt(0) || "C"

  const setupAudio = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Ensure FFT size is sufficient
    source.connect(analyser);
    // @ts-ignore
    audioContextRef.current = audioContext;
    // @ts-ignore
    analyserRef.current = analyser;
    updateAudioLevels();
  };

  const updateAudioLevels = () => {
    // @ts-ignore
    requestRef.current = requestAnimationFrame(updateAudioLevels);
    if (!analyserRef.current) return;
    // @ts-ignore
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    // @ts-ignore
    analyserRef.current.getByteFrequencyData(dataArray);

    const maxLevel = Math.max(...dataArray);
    const totalBars = 40;
    const normalizedMaxLevel = (maxLevel / 255) * totalBars; // Normalize against the full range
    setLitBars(Math.round(normalizedMaxLevel));
  };

  useEffect(() => {
    setupAudio();

    return () => {
      // @ts-ignore
      cancelAnimationFrame(requestRef.current);
      // @ts-ignore
      audioContextRef.current?.close();
    };
  }, []);


  useEffect(() => {
    if (!data) return
    setHasResume(data.hasResume)
  }, [data])



  return (
    <TooltipProvider>
      <div className="w-screen h-screen overflow-y-auto  pb-20">
        <Header
          logo={data?.organization?.logoUrl || logo}
          title={data?.organization?.name || ""}
        />
        <div className=" relative flex flex-col items-center">

          <Card className="mt-12 border-none shadow-none md:shadow md:border-1  h-[770px] ">
            <CardHeader className="overflow-hidden whitespace-wrap max-w-[450px]">
              <CardTitle>

                <img src={data?.organization?.logoUrl || logo}
                  // className="object-fit"
                  alt="Company Logo"
                  className="mb-4"
                  style={{
                    width: "40px",
                    height: '40px',
                    // objectFit: "fill",
                    aspectRatio: 1
                  }}
                />


                {data?.title}
              </CardTitle>
              <CardDescription>

                Your interview is ready, click the button below to start the interview
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4 pt-4 max-w-[100vw] overflow-hidden ">
              <div className="rounded overflow-hidden w-full h-[200px] sm:w-[448px] sm:h-[252px] bg-black relative flex items-center justify-center ">

                {webcamManager.userSetCamereOn && (
                  // @ts-ignore
                  webcamManager.activeRecordings?.map((recording, idx) => (
                    <video
                      key={recording?.id || idx}
                      className='webcam w-full h-full' ref={recording?.webcamRef} loop autoPlay playsInline muted />
                  ))
                )}

                {!webcamManager.userSetCamereOn && (
                  <div className="size-[120px] rounded-full bg-gray-100 flex items-center justify-center text-6xl">
                    <p className="text-gray-500">{intials?.toUpperCase()}</p>
                  </div>
                )}

                <div className="absolute bottom-2 left-2 p-2 rounded-xl flex space-x-1 items-center "
                  style={{
                    background: data?.organization?.color,
                    color: getContrastColorFromBackgroundColor(data?.organization?.color)
                  }}
                >
                  <User2Icon className="size-3" />
                  <p className="text-xs">{username}</p>
                </div>
              </div>

              <div className=" flex  px-4 py-2 rounded-xl space-x-2 w-full bg-gray-100 ">
                {micOn ? <Mic className={cn("size-5", micOn && "text-green-500")} /> : <MicOff className="size-5 " />}
                <div className="flex justify-center items-end gap-1 ">
                  {Array.from({ length: 40 }).map((_, index) => (
                    <div
                      key={index}
                      className={cn("rounded-lg w-[1px] sm:w-[4px] md:w-[5px]", (micOn && index < litBars) ? 'bg-green-700' : 'bg-gray-500')}
                      style={{
                        // width: '5px',
                        height: '22px',
                        // backgroundColor: index < litBars ? 'green' : 'grey',
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-between w-full space-x-2 ">
                <Button
                  className="w-full"
                  disabled={!!noMic}
                  variant={"outline"}
                  onClick={
                    () => {
                      setMicOn(!micOn);
                    }
                  }>
                  {micOn ? <Mic className="size-5  md:mr-2" /> : <MicOff className="size-5 text-rose-400 md:mr-2" />}
                  <span className="hidden sm:block">{micOn ? "Mute" : "Unmute"}</span>
                </Button>
                <Button
                  className="w-full"
                  disabled={webcamManager.noCameraError}
                  variant={"outline"}
                  onClick={async () => {
                    webcamManager.setUserSetCameraOn(!webcamManager.userSetCamereOn);
                    if (webcamManager.userSetCamereOn) {
                      await webcamManager.shutdownCamera();
                    } else {
                      await webcamManager.turnonCamera();
                    }
                  }}>
                  {webcamManager.userSetCamereOn ? <Video className="size-5  md:mr-2" /> : <VideoOff className="size-5 text-rose-400 md:mr-2" />}

                  <span className="hidden sm:block">
                    {webcamManager.userSetCamereOn ? "Stop camera" : "Start camera"}
                  </span>
                </Button>

                <SettingsDialog variant={"outline"} webcamManager={webcamManager} />
              </div>


            </CardContent>
            <CardFooter className="flex ">



              {hasResume ? (
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <Button
                      // variant="secondary"
                      disabled={!micOn}

                      className="w-full text-lg "
                      onClick={onClickStart}>
                      Start Interview
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="bg-black text-gray-200">
                    <p>You should have your microphone on in order to start the interview</p>
                  </TooltipContent>
                </Tooltip>
              ) : (
                <UploadInput
                  buttonOnly
                  buttonOnlyText='Upload Your Resume to Begin'
                  layoutClasses='w-full '
                  isLoading={isMutating > 0}
                  // name='resume'
                  // defaultValue={field.value}
                  mimeType={MIME_TYPE.PDF}
                  onChange={async (event) => {
                    const file = event.target.files?.[0]

                    if (!file) {
                      console.log('no file')
                      return
                    }

                    await update({
                      interviewUid,
                      resume: file
                    })

                    setHasResume(true)
                  }}
                />


              )}

            </CardFooter>

            <div className='max-w-xl mx-auto px-6 max-w-sm mb-8 text-center'>
              <p className='text-sm md:text-base'>This call may be recorded for quality and training purposes</p>
              <p className='text-muted-foreground text-sm md:text-base'>By clicking "Interview Now" you accept the<br /> <span><a
                className='underline'
                href='https://www.veton.ai/terms-of-service' target='__black'>terms and conditions</a></span> and <span><a className='underline' href="https://www.veton.ai/privacy-policy" target='__black'>privacy policy</a></span></p>
            </div>

          </Card>

        </div>

        <a
          href="https://veton.ai"
          target="_blank"
          className="p-4 text-lg flex justify-center items-center h-[60px] fixed bottom-0 left-0 w-full bg-white border-t border-gray-100 z-20 bg-white">
          Powered by <img
            alt='powered by veton.ai'
            src={logo} style={{ height: 25 }} className="ml-1" />
        </a>
      </div>
    </TooltipProvider>
  )
}



function Header({ logo, title, organizationUrl }: { logo: string, title: string, organizationUrl?: string, }) {
  const { isMobile } = useDims()
  return (


    <div className="w-full sticky top-0 z-10 mt-6 mb-4 lg:mt-4 bg-white hidden md:block">
      <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row  ">

        <div className="flex  items-center justify-center ">

          <div className="rounded overflow-hidden size-[60px] ">
            <img src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                width: "60px",
                height: '60px',
                // objectFit: "fill",
                aspectRatio: 1
              }}
            />
          </div>


          <div className="flex  flex-col ml-4">
            <p className="font-bold text-2xl">{title}</p>
          </div>

        </div>
        <div className="text-center mt-2">
          {organizationUrl && (
            <a href={organizationUrl} target="__blank">
              <Button
                variant={isMobile ? "link" : "secondary"}>Company Website</Button>
            </a>
          )}

        </div>
      </div>
    </div>

  )
}