import { TCommunicationForm } from "@/fetchers/usePosition";
import { z } from "zod";
import {
  applicationCompletedSMSTemplate,
  applicationEmailTemplate,
  applicationSMSTemplate,
  disqualifiedEmailTemplate,
  interviewCompletedEmailTemplate,
} from "./templates";

export const displayFormSchema = z.object({
  selectedMethod: z.enum(["email", "sms"], {
    required_error: "Please select a method.",
  }),

  applicationEmail: z.string().optional(),
  interviewCompletedEmail: z.string().optional(),

  applicationSMS: z.string().optional(),
  applicationCompletedSMS: z.string().optional(),

  afterApplication: z.string(),
  afterInterview: z.string(),
  interviewType: z.enum(["phone", "video"]),
  interviewDelay: z.enum([
    "no_delay",
    "fifteen_minutes",
    "thirty_minutes",
    "one_hour",
    "one_day",
    "two_days",
    "three_days",
  ]),

  autoSendOption: z.enum(["send_all", "good_fit", "maybe_and_up"]).optional(),

  disqualified: z.string().optional(),

  disqualifiedToggle: z.enum(["enabled", "disabled"]).optional(),
  autoInterview: z.boolean().optional().default(false),
});

export type DisplayFormValues = z.infer<typeof displayFormSchema>;

// This can come from your database or API.
export const CommunicationFormDefaultValues: Partial<DisplayFormValues> = {
  selectedMethod: "email",
  interviewType: "phone",
  interviewDelay: "no_delay",
  autoSendOption: "send_all",

  applicationEmail: applicationEmailTemplate,
  interviewCompletedEmail: interviewCompletedEmailTemplate,

  applicationSMS: applicationSMSTemplate,
  applicationCompletedSMS: applicationCompletedSMSTemplate,

  afterApplication: applicationEmailTemplate,
  afterInterview: interviewCompletedEmailTemplate,
  disqualified: disqualifiedEmailTemplate,

  disqualifiedToggle: "disabled",
  autoInterview: true,
};

export const mergeDataWithDefaults = (
  data?: TCommunicationForm
): DisplayFormValues => {
  return {
    selectedMethod: (data?.selectedMethod ||
      CommunicationFormDefaultValues.selectedMethod) as DisplayFormValues["selectedMethod"],

    afterApplication: (data?.afterApplication ||
      CommunicationFormDefaultValues.afterApplication) as DisplayFormValues["afterApplication"],
    afterInterview: (data?.afterInterview ||
      CommunicationFormDefaultValues.afterInterview) as DisplayFormValues["afterInterview"],

    interviewType: (data?.interviewType ||
      CommunicationFormDefaultValues.interviewType) as DisplayFormValues["interviewType"],
    interviewDelay: (data?.interviewDelay ||
      CommunicationFormDefaultValues.interviewDelay) as DisplayFormValues["interviewDelay"],

    autoSendOption:
      data?.autoSendOption || CommunicationFormDefaultValues.autoSendOption,

    disqualified:
      data?.disqualified || CommunicationFormDefaultValues.disqualified,

    disqualifiedToggle: data?.disqualified ? "enabled" : "disabled",
    autoInterview: data?.autoInterview as DisplayFormValues["autoInterview"],

    // interviewType: "video",
    // interviewDelay: "No delay",
  };
};
