import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"

import { cn } from "@/lib/utils"

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { TAICofigForm, TPosition, useAIConfigformMutation } from "@/fetchers/usePosition"
import { PlayCircle, TriangleAlert, XIcon } from "lucide-react"
import { useRef, useState } from "react"
import { AI_VOICES } from "../details/constants"
import { useIsMutating } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router"


import aliceAvatar from '@/assets/images/alice_avatar.png'
import alexAvatar from '@/assets/images/alex_avatar.png'
import emmaAvatar from '@/assets/images/emma_avatar.png'
import jamesAvatar from '@/assets/images/james_avatar.png'

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

const profileFormSchema = z.object({
  voiceModel: z.enum(["shimmer", "echo", "nova", "onyx"]).default("echo"),
  additionalQuestions: z
    .array(
      z.object({
        value: z.string(),
      })
    )
    .optional(),
  // restrictQuestions: z.boolean().optional().default(false),
  restrictQuestions: z.enum(["enabled", "disabled"]).default("disabled").optional(),
})

type AIConfigFormValues = z.infer<typeof profileFormSchema>

// This can come from your database or API.
const defaultValues: Partial<AIConfigFormValues> = {
  voiceModel: "echo",
  additionalQuestions: [

    { value: "" }
    // { value: "Do you give consent to proceed with the interview process?" },
    // { value: "Confirm candidate's laptop model" },
    // { value: "Confirm candidate's internet speed" },
    // { value: "Confirm candidate's favorite color" },
    // { value: "Ask where the candidate is from" },
  ],
  restrictQuestions: "disabled",
}


export function AIConfigForm({ position, aiConfigForm }: { position?: TPosition, aiConfigForm?: TAICofigForm }) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false })
  const navigate = useNavigate()
  const updateFormMutation = useAIConfigformMutation()
  const isMutating = useIsMutating()
  const audioRef = useRef<HTMLAudioElement>(null)


  const remoteQuestions = aiConfigForm?.additionalQuestions?.map(value => ({ value })) || []
  const additionalQuestions = remoteQuestions?.length > 0 ? remoteQuestions : defaultValues.additionalQuestions
  const [noFieldsLeft, setNoFieldsLeft] = useState(remoteQuestions?.length === 0)

  const form = useForm<AIConfigFormValues>({
    resolver: zodResolver(profileFormSchema),
    mode: "onChange",
    defaultValues: {
      voiceModel: aiConfigForm?.voiceModel || defaultValues.voiceModel,
      additionalQuestions: additionalQuestions,
      restrictQuestions: aiConfigForm?.restrictQuestions ? "enabled" : "disabled" || defaultValues.restrictQuestions,
      // restrictQuestionsToggle: aiConfigForm?.restrictQuestions ? "enabled" : "disabled",
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: "additionalQuestions",
    control: form.control,
  })

  function onSubmit(data: AIConfigFormValues) {
    const additionalInfo = data?.additionalQuestions?.map((question) => question.value).filter(Boolean)
    if (!position?.slug) {
      return toast.error("Position is missing")
    }
    const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0
    const restrictQuestions = hasAdditionalInfo && data.restrictQuestions === "enabled"
    updateFormMutation({
      slug,
      organizationId,
      data: {
        voiceModel: data.voiceModel,
        additionalQuestions: additionalInfo,
        restrictQuestions,
      }
    })

    navigate({
      // to: "/new-position/$step",
      to: "/organizations/$organizationId/positions/manage/$slug/$step",
      params: {
        organizationId: String(organizationId),
        slug: slug,
        step: "hiring-team"
      }
    })

    // toast(
    //   "You submitted the following values:",
    //   {
    //     description: (
    //       <pre className="mt-2 w-[325px] rounded-md bg-slate-950 p-2">
    //         <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //       </pre>
    //     ),
    //   })
  }

  return (
    <>

      <audio ref={audioRef} className="hidden" />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">


          <FormField
            control={form.control}
            name="voiceModel"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel>Select AI Interviewer</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-wrap w-full items-center justify-start"
                  >
                    <FormItem className="flex items-center  space-y-0" >
                      <FormLabel className="[&:has([data-state=checked])>div]:border-primary">

                        <FormControl onClick={
                          () => {
                            if (!audioRef.current) return
                            audioRef.current.src = AI_VOICES.avatarAlex
                            audioRef.current?.play()
                          }
                        }>
                          <RadioGroupItem value="echo" className="sr-only" />
                        </FormControl>
                        <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent p-4">
                          <img src={alexAvatar} alt="Alex Avatar" className="size-[140px] rounded-full border border-black" />
                        </div>

                        <div className="justify-center flex items-center block w-full p-2 text-center font-normal">
                          <PlayCircle className="mr-2 size-5" /> Alex (US Male)
                        </div>

                      </FormLabel>
                    </FormItem>

                    <FormItem className="flex items-center space-y-0">
                      <FormLabel className="[&:has([data-state=checked])>div]:border-primary pointer-cursor">
                        <FormControl onClick={
                          () => {
                            if (!audioRef.current) return
                            audioRef.current.src = AI_VOICES.avatarAlice
                            audioRef.current?.play()
                          }
                        }>
                          <RadioGroupItem value="shimmer" className="sr-only" />
                        </FormControl>

                        <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent p-4">

                          <img src={aliceAvatar} alt="Alice Avatar" className="size-[140px] rounded-full border border-black" />
                        </div>

                        <div className="justify-center flex items-center block w-full p-2 text-center font-normal">
                          <PlayCircle className="mr-2 size-5" /> Alice (US Female)
                        </div>

                      </FormLabel>
                    </FormItem>


                    <FormItem className="flex items-center  space-y-0">
                      {/* <FormControl>
                        <RadioGroupItem value="echo" />
                      </FormControl> */}
                      <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                        <FormControl onClick={
                          () => {
                            if (!audioRef.current) return
                            audioRef.current.src = AI_VOICES.avatarEmma
                            audioRef.current?.play()
                          }
                        }>
                          <RadioGroupItem value="nova" className="sr-only" />
                        </FormControl>
                        <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent p-4">
                          <img src={emmaAvatar} alt="Emma Avatar" className="size-[140px] rounded-full border border-black" />
                        </div>

                        <div className="justify-center flex items-center block w-full p-2 text-center font-normal">
                          <PlayCircle className="mr-2 size-5" /> Emma (US Female)
                        </div>

                      </FormLabel>
                    </FormItem>



                    <FormItem className="flex items-center  space-y-0">
                      {/* <FormControl>
                        <RadioGroupItem value="echo" />
                      </FormControl> */}
                      <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                        <FormControl onClick={
                          () => {
                            if (!audioRef.current) return
                            audioRef.current.src = AI_VOICES.avatarJames
                            audioRef.current?.play()
                          }
                        }>
                          <RadioGroupItem value="onyx" className="sr-only" />
                        </FormControl>
                        <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent p-4">
                          <img src={jamesAvatar} alt="James Avatar" className="size-[140px] rounded-full border border-black" />
                        </div>

                        <div className="justify-center flex items-center block w-full p-2 text-center font-normal">
                          <PlayCircle className="mr-2 size-5" /> James (US Male)
                        </div>

                      </FormLabel>
                    </FormItem>


                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />


          <div>
            {fields.map((field, index) => (
              <FormField
                control={form.control}
                key={field.id}
                name={`additionalQuestions.${index}.value`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className={cn(index !== 0 && "sr-only")}>
                      Must ask questions
                    </FormLabel>
                    <FormDescription className={cn(index !== 0 && "sr-only")}>
                      Our AI interviewers are robust enough to identify the right questions. However if there are must ask questions, you can add them here.
                    </FormDescription>
                    <FormControl>
                      <div className="flex max-w-xl relative" >
                        <Input
                          {...field}
                          onChange={(event) => {
                            if (event.target.value === "") {
                              setNoFieldsLeft(true)
                            }
                            else {
                              setNoFieldsLeft(false)
                            }

                            field.onChange(event.target.value)
                          }}
                          placeholder="Do you believe you can be successful working remotely?"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              append({ value: "" });
                            }
                          }}
                        />

                        <Button
                          variant='ghost'
                          type="button"
                          className="absolute right-[1px] top-[1px] bg-gray-50/90 h-[38px]"
                          onClick={(event) => {
                            event.preventDefault()
                            // check if there is only one question
                            if (fields.length === 1) {
                              // if only one clear its value
                              field.onChange("")
                              setNoFieldsLeft(true)
                            } else {
                              remove(index)
                            }
                          }}
                        >
                          <XIcon className="size-5" />
                        </Button>

                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}

            <Button
              type="button"
              variant="outline"
              size="sm"
              className="mt-2"
              onClick={() => append({ value: "" })}
            >
              Add more questions
            </Button>
          </div>

          <TooltipProvider>
            <FormField
              control={form.control}
              name="restrictQuestions"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Tooltip>
                      <TooltipTrigger onClick={event => event.preventDefault()} className="w-full">
                        <Tabs defaultValue={aiConfigForm?.restrictQuestions ? "enabled" : "disabled"} className={cn(noFieldsLeft && "opacity-50")} onValueChange={field.onChange}>
                          <div className="flex justify-between items-center">
                            <FormLabel className="">Restrict Questions (Recommended for specific use cases only) </FormLabel>
                            <TabsList>
                              <TabsTrigger disabled={noFieldsLeft} value="disabled">Disabled</TabsTrigger>
                              <TabsTrigger disabled={noFieldsLeft} value="enabled">Enabled</TabsTrigger>
                            </TabsList>
                          </div>
                          <TabsContent value="disabled" className="w-full">
                            <FormDescription className="w-2/4 text-left">

                              {/* {field.value === "enabled" && ()} */}
                              If you want the AI to ask <span className="font-bold">only</span> the questions you have added above, you can enable this feature.
                            </FormDescription>
                          </TabsContent>

                          <TabsContent className="w-full" value="enabled">
                            <Alert className="text-left">
                              <TriangleAlert className="h-4 w-4" />
                              <AlertTitle>Heads up!</AlertTitle>
                              <AlertDescription>
                                Interviewer will only ask the questions you have added above. No other questions or follow-ups will be asked.
                              </AlertDescription>
                            </Alert>

                          </TabsContent>
                        </Tabs>
                      </TooltipTrigger>
                      {noFieldsLeft && (
                        <TooltipContent>
                          <p>Add custom questions to enable this feature</p>
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </FormControl>


                  <FormMessage />
                </FormItem>
              )}
            />
          </TooltipProvider>



          <Button
            isLoading={isMutating > 0}
            type="submit"
            className="float-right"
          >{position ? "Update" : "Next"}</Button>
        </form>
      </Form>
    </>
  )
}
