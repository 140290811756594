import { Separator } from "@/components/ui/separator"
import { CommunicationForm } from "./communcation-form"
import { TPosition, useCommunicationForm } from "@/fetchers/usePosition"
import { Loader } from "../../loader"

export default function CommunicationFormView({ position }: { position?: TPosition }) {

  const { data, isFetching } = useCommunicationForm({
    slug: position?.slug
  })

  if (isFetching) {
    return (
      <Loader />
    )
  }

  return (
    <div className="space-y-6  lg:max-w-2xl  pb-[120px]">
      <div>
        <h3 className="text-lg font-medium">Communication method</h3>
        <p className="text-sm text-muted-foreground">
          Customize the communication method for this position.
        </p>
      </div>
      <Separator />
      <CommunicationForm communicationForm={data} />
    </div>
  )
}
