

import { Separator } from "@/components/ui/separator"
import { DetailsForm } from "./details-form"
import { TPosition } from "@/fetchers/usePosition"


export function DetailsFormView({ position }: { position?: TPosition }) {
  return (
    <div className="space-y-6  lg:max-w-2xl pb-[120px]">
      <div>
        <h3 className="text-lg font-medium">Position Details</h3>
        {/* <p className="text-sm text-muted-foreground">
          Incorporate key information about your role, including Position Title, Type, and Location.
        </p> */}
      </div>
      <Separator />
      <DetailsForm position={position} />
    </div>
  )
}
