import { Loader } from '@/components/core/loader';
import { AnalysisView } from '@/components/core/scenario/Analysis';
import { Interview } from '@/components/core/scenario/Interview';
import { applicationThankYouMessage } from '@/constants/interview';
import { useInterviewQuery } from '@/fetchers/useInterview';

import { useBlocker, useParams } from '@tanstack/react-router'
import { ErrorView } from '../ErrorView';
import { useAudioStream } from '@/lib/useAudioStream';

export function InterviewPage() {
  // @ts-ignore
  const { interviewId: interviewUUid } = useParams({ strict: false })

  const { data: interview, isFetching, error } = useInterviewQuery({
    interviewUUid: interviewUUid !== "general-application" ? interviewUUid : undefined,
  })

  const { setIsStarted, aiTalking, endStream, data: generalApplicationData } = useAudioStream({
    interview
  });



  useBlocker(
    () => window.confirm('Are you sure you want to leave?'),
    !isFetching && !error && interview?.id && !interview?.completedAt
  )


  if (isFetching) {
    return <Loader />
  }


  if (error || interview?.interviewType === "phone") {
    return (
      <ErrorView
        statusCode={401}
        title="Unauthorized Access"
        description="You are not authorized to view this interview"
      />
    )
  }
  if (!interview && interviewUUid !== "general-application") {
    return (

      <ErrorView
        statusCode={404}
        title="Interview Not Found"
        description="Interview not found"
      />
    )
  }

  if (interview?.completedAt) {
    return (
      <div className='max-w-4xl mx-auto w-full'>
        <AnalysisView analysis={applicationThankYouMessage(interview?.title ?? "this position")} />
      </div>
    )
  }


  return (
    <Interview
      data={interview}
      generalApplicationData={generalApplicationData}
      startStream={() => setIsStarted(true)}
      aiTalking={aiTalking}
      endStream={endStream}
    />
  )
}
