import { MoreHorizontal } from "lucide-react"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardFooter,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Separator } from '@/components/ui/separator';
import { TeamMember, useToggleTeamMemberRole, useGetTeamList } from "@/fetchers/useOrganization"
import { Loader } from "../loader"
import { useMemo } from "react"

type TProps = {
  searchTerm?: string
}
export function TeamSettings({ searchTerm = '' }: TProps) {
  const { data: teamMembers, isFetching } = useGetTeamList()

  const updateMember = useToggleTeamMemberRole()

  const filteredMembers = useMemo(() => {
    if (!teamMembers) {
      return []
    }

    if (!searchTerm) {
      return teamMembers
    }

    return teamMembers.filter(member => {
      const email = member?.email?.toLowerCase().includes(searchTerm.toLowerCase())
      const name = member?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
      const role = member?.role?.toLowerCase().includes(searchTerm.toLowerCase())

      return email || name || role
    })
  }, [teamMembers, searchTerm])


  if (isFetching && teamMembers?.length === 0) {
    return (
      <div className="h-[70vh] bg-red-300">
        <Loader />
      </div>
    )
  }

  return (
    <Card className=" flex flex-col justify-between pt-4 ">
      {/* <CardHeader>
        <CardTitle>Members</CardTitle>
        <CardDescription>
          Manage your team members and their roles.
        </CardDescription>
      </CardHeader> */}
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              {/* <TableHead className="hidden w-[100px] sm:table-cell">
                <span className="sr-only">Image</span>
              </TableHead> */}
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Role</TableHead>
              {/* <TableHead className="hidden md:table-cell">
                Total Sales
              </TableHead> */}
              {/* <TableHead className="hidden md:table-cell">Created at</TableHead> */}
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredMembers?.map((member) => (
              <Row key={member.id} member={member} updateMember={updateMember} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>{filteredMembers?.length || 0}</strong> members
        </div>
      </CardFooter>
    </Card>
  )
}


function Row({
  member,
  updateMember
}: {
  member: TeamMember,
  updateMember: (data: { memberId: number, role?: "admin" | "member", remove?: boolean }) => void
}) {
  return (
    <TableRow>
      <TableCell className="font-medium flex items-center">
        <Avatar className="mr-2 size-10">
          <AvatarImage
            src={member?.imageUrl || ""}
            alt={"Team member"}
          // className="grayscale"
          />
          <AvatarFallback>{member?.email?.slice(0, 2)?.toUpperCase()}</AvatarFallback>
        </Avatar>
        <p className="text-sm text-muted-foreground truncate w-24 sm:w-auto">
          {member?.fullName || member?.email?.split("@")[0] || ""}
        </p>
      </TableCell>



      <TableCell>{member?.email}</TableCell>

      <TableCell>
        <Badge className="-ml-1 w-20 flex items-center justify-center" variant={"outline"}>{member?.role?.toUpperCase()}</Badge>
      </TableCell>


      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => {
              updateMember({
                memberId: member?.id,
                role: member?.role === "admin" ? "member" : "admin"
              })
            }}>
              {member?.role === "admin" ? "Switch to Member" : "Switch to Admin"}
            </DropdownMenuItem>
            <Separator className="my-1" />
            <DropdownMenuItem
              onClick={() => {
                updateMember({
                  memberId: member?.id,
                  remove: true
                })
              }}
              className="0">Remove</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  )
}