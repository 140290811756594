import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
// import { toast } from "sonner"
// import { MIME_TYPE, UploadInput } from "../../common/UploadInput"
import { Input } from "@/components/ui/input"

import { useNavigate, } from "@tanstack/react-router"
import { useState } from "react"
import ImageCropper from "../common/ImageCropper"
import { RequiredStar } from "../common/RequiredStar"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { ParentOrganization, useCreateParentOrganization, useUpdateParentOrganization } from "@/fetchers/useOrganization"
import { toast } from "sonner"
import { cn } from "@/lib/utils"


const displayFormSchema = z.object({
  logo: z.union([z.string(), z.instanceof(File)]).optional(),
  name: z.string({
    required_error: "Company name is required.",
  }),
  webUrl: z.string().url({
    message: "Please enter a valid website domain.",
  }).optional(),
})

type CreateParentOrganizationValues = z.infer<typeof displayFormSchema>

// This can come from your database or API.
const defaultValues: Partial<CreateParentOrganizationValues> = {
  logo: undefined,
  name: undefined,
  webUrl: undefined,
}

type TProps = {
  organization?: ParentOrganization,
  layoutClasses?: string
}
export function CreateParentOrganizationForm({ organization, layoutClasses = '' }: TProps) {
  const navigate = useNavigate()
  const [cropperOpen, setCropperOpen] = useState(false)
  const update = useUpdateParentOrganization()
  const create = useCreateParentOrganization()

  const form = useForm<CreateParentOrganizationValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      name: organization?.name || defaultValues.name,
      logo: organization?.logoUrl || defaultValues.logo,
      webUrl: organization?.webUrl || defaultValues.webUrl,
    },
  })



  async function onSubmit(data: CreateParentOrganizationValues) {
    const payload = {
      name: data.name,
      logo: typeof data.logo === "string" ? undefined : data.logo,
      webUrl: data.webUrl
    }
    let res;
    if (organization) {
      res = await update(payload)
    } else {
      res = await create(payload)
    }
    if (res?.id) {
      form.reset()
      navigate({
        to: "/organizations",
      })
    } else {
      toast.error("Something went wrong. Please try again.")
    }

  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">

        <Card className={cn("w-full max-w-[500px] overflow-hidden w-[300px] sm:px-0 sm:w-[400px] md:w-[450px]", layoutClasses)}>
          <CardHeader>
            <CardTitle>{organization ? "Update" : "Create"} your organization</CardTitle>
            {/* <CardDescription>{organization ? "Update" : "Create"} your organization</CardDescription> */}
          </CardHeader>
          <CardContent className="max-w-[450px] overflow-hidden ">
            <FormField
              control={form.control}
              name='logo'
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Company Logo (260 x 260)</FormLabel>



                  <ImageCropper
                    open={cropperOpen}
                    setOpen={setCropperOpen}
                    onChange={field.onChange}
                    aspectRatio={1}
                    defaultValue={field.value}
                    layoutClasses='size-[160px]'
                  />


                  <FormMessage />
                </FormItem>
              )}
            />


            <FormField
              control={form.control}
              name='name'
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="w-full mt-4">

                  <FormLabel aria-required>Company Name<RequiredStar /></FormLabel>
                  <FormDescription>

                  </FormDescription>
                  <FormControl>
                    <Input placeholder="Acme Inc." {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />


            <FormField
              control={form.control}
              name='webUrl'
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="w-full mt-4">

                  <FormLabel aria-required>Company Website</FormLabel>
                  <FormDescription>

                  </FormDescription>
                  <FormControl>
                    <Input placeholder="https://www.domain.com" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

          </CardContent>
          <CardFooter className="">

            <Button
              isLoading={form.formState.isSubmitting}
              className="" type="submit">{organization ? "Update" : "Create"}
            </Button>
          </CardFooter>
        </Card>










      </form>
    </Form>
  )
}
