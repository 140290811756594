
import { ScrollArea } from "@/components/ui/scroll-area"
import { ItemPreview } from "@/components/core/management/item-preview"
// import { usePanelStore } from "@/lib/usePanelStore";



type TItem = {
  slug: string,
  title: string;
  // status: string;
  // type: string;
  subtitle: string;
  // sessionId: number;
  content: string | null;
  createdAt: string | null;
  tags: string[];
  isOpen: boolean;
  disabled?: boolean;
  // created_at: string;
  // started_at: string | null;
  // updated_at: string;
}
export type ItemListProps = {
  dndEnabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[] | TItem[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (item: any | TItem) => void;
  layoutClasses?: string;
  // handleTogglePublishPosition?: (item: TItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // handleOnEdit?: (item: any | TItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownActions?: (item: any) => {
    title: string;
    onClick: () => void;
    customClasses?: string;
  }[]
}



export function ItemList({ items, onClick, layoutClasses = "", dropdownActions, dndEnabled = false }: Readonly<ItemListProps>) {
  // const { setPanelItem } = usePanelStore()
  return (
    // @ts-ignore
    <ScrollArea className={layoutClasses} viewPortStyle={dndEnabled ? {
      overflowX: "visible",
      overflowY: "visible",
      overflow: "visible"
    } : {}}>
      <div className="flex flex-col gap-2 p-4 pt-0 pb-40  max-w-2xl xl:max-w-4xl mx-auto h-[80vh] overflow-auto no-scrollbar">
        {items.map((item, idx) => (
          <ItemPreview
            draggable={dndEnabled}
            key={item?.applicationId || idx}
            id={item?.applicationId}
            title={item?.title}
            titleJSX={item?.titleJSX}
            completedAt={item?.createdAt}
            subtitle={item?.subtitle}
            tags={item?.tags || []}
            onClick={() => onClick?.(item)}
            content={item?.content}
            dropdownActions={dropdownActions?.(item)}
            // handleTogglePublishPosition={() => handleTogglePublishPosition?.(item)}
            // handleOnEdit={() => handleOnEdit?.(item)}
            // displayActions={!!handleOnEdit && !!handleTogglePublishPosition}
            isOpen={item?.isOpen}
            disabled={item?.disabled}
            applicantCount={item?.applicantCount}
          />
        ))}
      </div>
    </ScrollArea>
  )
}


