export const MOCK_INTERVIEW_RULES = [
  {
    id: 0,
    title: "Start whenever you are ready.",
    description:
      "You can start the interview whenever you are ready. After you start press play on upper right corner of the navbar",
  },
  {
    id: 1,
    title: "Be polite and courteous",
    description:
      "Address the AI interviewer with respect, as you would in any other interview.",
  },
  {
    id: 2,
    title: "Speak clearly and concisely",
    description:
      "Make sure your voice is clear and audible, and that your answers are direct and to the point.",
  },
  {
    id: 3,
    title: "Avoid distractions",
    description:
      "Minimize background noise, and avoid looking at your phone or any other distractions during the interview.",
  },
  {
    id: 4,
    title: "Answer truthfully",
    description:
      "Provide honest and accurate answers to the questions asked by the AI interviewer.",
  },
  {
    id: 5,
    title: "Stay focused",
    description:
      "Stay focused on the interview and give your full attention to the AI interviewer at all times.",
  },
  {
    id: 6,
    title: "Avoid interruptions",
    description:
      "Do not interrupt the AI interviewer or other candidates during the interview.",
  },
  {
    id: 7,
    title: "Follow the interview process",
    description:
      "Answer the questions asked by the AI interviewer in the order they are presented. Do not deviate from the process.",
  },
  {
    id: 8,
    title: "Take your time",
    description:
      "Take your time to think about your answers, and do not be afraid to ask for clarification if you need it.",
  },
];

export const applicationThankYouMessage = (companyName: string) => `
We would like to sincerely thank you for taking the time to participate in our innovative, AI-led interview process. Your dedication, enthusiasm, and interest in ${companyName} have not gone unnoticed, and we appreciate the efforts you put into answering our set of questions.

We are committed to embracing cutting-edge technology, and our AI-led interviews are a testament to that. This new approach enables us to get to know you better, beyond just your resume, and gives us insights into your skills and suitability for the role, no matter where in the world you might be located.

We understand that this format may be different from traditional interviews, so we are particularly grateful for your flexibility and openness in experiencing this novel approach with us.

Your responses have been logged and saved in our database. Rest assured, a human element still prevails in our recruitment process. Our hiring team, including recruiters and hiring managers, will thoroughly review and analyze your responses. They will be evaluating your expertise, skills, and potential fit with our team and the role for which you applied.

We value every application that we receive, and we want you to know that each one is given serious consideration. As we meticulously review your interview responses, we request your patience.

Once again, thank you for investing your time with us today. Your interest in becoming a part of ${companyName} is truly appreciated. Regardless of the outcome of this application, we want to acknowledge your initiative and the courage it takes to step into this process.

We are excited about the possibility of you joining our team and look forward to getting back to you soon. If you have any questions or need further information in the meantime, please do not hesitate to contact us.
`;

export const ASSISTANT_TYPES = {
  MOCK_INTERVIEW: "mock_interview",
  RESTRICTED: "restricted",
};
