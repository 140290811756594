import { cn } from "@/lib/utils"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useNavigate, useRouteContext } from "@tanstack/react-router"
import { ParentOrganization, useOrganizationStore } from "@/fetchers/useOrganization"
import { useUserDetailStore } from "@/fetchers/useUserDetails"

interface AccountSwitcherProps {
  isCollapsed: boolean
  memberships: ParentOrganization[]
}

export function AccountSwitcher({
  isCollapsed,
  memberships
}: AccountSwitcherProps) {
  const { auth: { signOut } } = useRouteContext({ strict: false })
  const { selectedParentOrganization, setSelectedParentOrganization } = useOrganizationStore()
  const { setSelectedOrganization } = useUserDetailStore()
  const navigate = useNavigate()


  return (
    <Select
      value={String(selectedParentOrganization?.id)}
      onValueChange={async (val) => {
        if (val === "logout") {
          signOut()
          return
        }
        const selectedMembership = memberships?.find((org) => String(org.id) === val) || memberships?.[0]
        setSelectedParentOrganization(selectedMembership)
        setSelectedOrganization()

        navigate({ to: "/organizations" })



        // await queryClient.invalidateQueries();
        // navigate({
        //   to: "/organizations",
        //   // params: {
        //   //   organizationId: String(selectedOrganization?.id)
        //   // }
        // })
      }}>
      <SelectTrigger
        className={cn(
          "flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 bg-gray-50 border-0",
          isCollapsed &&
          "flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden",
          "max-w-[90%]"
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select a source">
          <img
            width={20}
            height={20}
            style={{
              objectFit: "fill",
              borderRadius: 4
            }}
            src={memberships.find((org) => org.id === selectedParentOrganization?.id)?.logoUrl}
          />
          <span className={cn("ml-2 overflow-hidden text-ellipsis", isCollapsed && "hidden")}>
            {
              memberships.find((org) => org.id === selectedParentOrganization?.id)
                ?.name
            }
          </span>

        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {memberships.map((membership) => (
          <SelectItem key={membership.id} value={String(membership.id)}>
            <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              <img
                width={20}
                height={20}
                src={membership.logoUrl}
              />
              {membership.name}
            </div>
          </SelectItem>
        ))}

      </SelectContent>
    </Select >
  )
}
