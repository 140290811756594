
import { CareerView } from '@/components/core/careers/single'
import { Loader } from '@/components/core/loader'
import { useGetPublicOrganization } from '@/fetchers/useOrganization'
import { useGetPublicPosition } from '@/fetchers/usePosition'
import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/careers/$organizationId/$jobId')({
  component: View,
})

function View() {
  const { jobId, organizationId } = Route.useParams()
  const { data, isFetching: isFetchingPosition } = useGetPublicPosition({ slug: jobId })

  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug: organizationId,
  })


  if (isFetching || isFetchingPosition) {
    return (
      <Loader />
    )
  }

  if (data?.organizationSlug !== organization?.slug) {
    return <Navigate
      to='/careers/$organizationId'
      params={{ organizationId }}
    />
  }

  return (
    <>
      {/* @ts-ignore */}
      <CareerView position={data} organization={organization} />
    </>
  )
}