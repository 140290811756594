import { LayersLoader } from './layers';
import './loader.css';

export function Loader({ variant = 'layers' }: { variant?: 'ball' | 'layers' } = { variant: 'layers' }) {

  if (variant === "layers") {
    return (
      <LayersLoader />
    )
  }

  return (
    <div className='h-[75vh]'>
      <div className='w-full h-full flex items-center justify-center bg-gray-200'>
        <div />
      </div>
    </div>
  )
}