import { Button } from "@/components/ui/button"



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InsertVariables({ customRef }: { customRef: any }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function insertVariable(ref: any, variable: string) {
    try {
      const editor = ref.current.getEditor()
      const cursorPosition = editor.getSelection().index;
      editor.insertText(cursorPosition, variable);
      editor.setSelection(cursorPosition + variable.length + 1);
    } catch {
      console.log("Error inserting variable")
    }
  }

  const variables = [
    { label: "Company Name", value: " {{company_name}} " },
    { label: "Candidate's Name", value: " {{candidate_name}} " },
    { label: "Position Name", value: " {{position_name}} " },
    // { label: "Interview url", value: " {{interview_url}} " },
    // { label: "Interview Date", value: " {{interview_date}} " },
    { label: "Company Email", value: " {{company_email}} " },
  ]

  return (
    <div className="-mt-2 lg:-mt-6">
      <p>Avabilable variables:</p>
      <div className="">
        {variables.map(variable => (
          <Button
            key={variable.value}
            className="rounded-xl text-xs p-2 h-6 mr-1 mb-1"
            onClick={(event) => {
              event.preventDefault()
              insertVariable(customRef, variable.value)
            }}>
            {variable.label}
          </Button>

        ))}

      </div>
    </div>
  )
}