import Cookies from 'js-cookie'
import { Layout } from '@/components/core/dashboard/layout'
// import { useUserDetailStore, useUserDetails } from '@/fetchers/useUserDetails'
// import { NoOrganizationNavbar } from './NoOrganizationNavbar'


export default function MailPage({ children }: { children: React.ReactNode }) {

  const layout = Cookies.get("react-resizable-panels:layout")
  const collapsed = Cookies.get("react-resizable-panels:collapsed")

  const defaultLayout = layout ? JSON.parse(layout) : undefined
  const defaultCollapsed = collapsed ? JSON.parse(collapsed) : undefined

  // const { selectedOrganization } = useUserDetailStore()
  // const { data } = useUserDetails()

  // const organizations = data?.organizations
  // if (organizations?.length === 0) {
  //   return (
  //     <>
  //       <div>
  //         <NoOrganizationNavbar />
  //         <div>
  //           {children}
  //         </div>
  //       </div>
  //     </>
  //   )
  // }

  return (
    <>
      {/* <div className="md:hidden">
        <p>Mobile view</p>
      </div> */}
      <div className="flex-col flex h-screen w-screen overflow-hidden">
        <Layout
          // mails={mails}
          defaultLayout={defaultLayout}
          defaultCollapsed={defaultCollapsed}
          navCollapsedSize={4}>
          {children}
        </Layout>
      </div>
    </>
  )
}
