export const applicationEmailTemplate = "<p>Dear {{candidate_name}},</p><p><br></p><p>Thank you very much for applying to the {{position_name}} at {{company_name}}. We are ready for your interview!</p><p><br></p><p>Whenever you are ready, please click on the link below to start your interview.</p>"


export const interviewCompletedEmailTemplate = "<p>Dear {{candidate_name}},</p><p><br></p><p>Thank you for completing the interview for {{position_name}} at {{company_name}} . We will review your interview and get back to you. If you have any questions, please feel free to contact us at {{company_email}}.</p><p><br></p><p>All the best,</p><p>{{company_name}}</p>"


export const applicationSMSTemplate = `Dear {{candidate_name}}, thank you for applying for {{position_name}}. Your interview link will be send within today. Once you receive the link you will have 24 hours to complete the interview. If you have any questions, please feel free to contact us at {{company_email}}. We look forward to see your interview once you complete it. All the best, {{company_name}}`

export const applicationCompletedSMSTemplate = `Dear {{candidate_name}}, thank you for completing the interview for {{position_name}}. We will review your interview and get back to you. If you have any questions, please feel free to contact us at {{company_email}}. All the best, {{company_name}}`

export const disqualifiedEmailTemplate = "<p>Dear {{candidate_name}},</p><p><br></p><p>We regret to inform you that you have been disqualified from the application process for {{position_name}}. If you have any questions, please feel free to contact us at {{company_email}}.</p><p><br></p><p>All the best,</p><p>{{company_name}}</p>"


export const delayLabels = {
  no_delay: "No Delay",
  fifteen_minutes: "15 Minutes",
  thirty_minutes: "30 Minutes",
  one_hour: "1 Hour",
  one_day: "1 Day",
  two_days: "2 Days",
  three_days: "3 Days",
}

export const autoSendOptions = {
  send_all: "Send All",
  good_fit: "Good Fits Only",
  maybe_and_up: "Maybe and Up",
}

