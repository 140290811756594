import { Button } from "@/components/ui/button"

import {
  Dialog,
  DialogContent,

  DialogTrigger,
} from "@/components/ui/dialog"

import { useState } from "react"


import { PlusIcon } from "lucide-react";
import { ApplicationForm } from "../careers/single/application-form";
import { useGetPublicPosition } from "@/fetchers/usePosition";
import { useParams } from "@tanstack/react-router";


export function NewApplicantDialog() {
  const [open, setOpen] = useState(false);

  // @ts-ignore
  const { slug } = useParams({ strict: false })

  const { data: position, isFetching: isFetchingPosition } = useGetPublicPosition({ slug })



  if (isFetchingPosition) {
    return null
  }


  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button
          className='h-[40px]'
          variant="secondary"
        // onClick={() => navigate({
        //   to: `/organizations/$organizationId/positions/create`,
        //   params: { organizationId: String(organizationId) }
        // })}
        >
          <PlusIcon className='size-5 mr-1' />
          Add Applicant
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[70vh] overflow-y-auto">



        <ApplicationForm
          formTitle="Add Applicant"
          // @ts-ignore
          config={position?.applicationForm}
          // @ts-ignore
          position={position}
          onSubmit={() => setOpen(false)}
        />


      </DialogContent>
    </Dialog>
  )
}



