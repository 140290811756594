import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { SelectSeparator } from "@/components/ui/select"
import { Tabs } from "@/components/ui/tabs"
import { LinkIcon, PlusIcon, Search } from "lucide-react"
import { useMemo, useState } from "react"
import creatingSvg from '@/assets/svg/creating.svg'
import { useNavigate } from "@tanstack/react-router"
import { Organization, useUserDetailStore } from "@/fetchers/useUserDetails"
import { Separator } from "@/components/ui/separator"

type TProps = {
  sources: Organization[]
}
export function Sources({ sources }: TProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTab, setSelectedTab] = useState('all')
  const navigate = useNavigate()
  // @ts-ignore
  // const { organizationId } = useParams({ strict: false })

  const filteredItems = useMemo(() => {
    return sources.filter(item => {
      const matchesTitle = item.name.toLowerCase().split(searchTerm.toLowerCase()).length > 1
      const matchesSubtitle = item.companyDescription.toLowerCase().split(searchTerm.toLowerCase()).length > 1
      // const matchesContent = item.content.toLowerCase().split(searchTerm.toLowerCase()).length > 1

      if (selectedTab === "all") {
        return matchesTitle || matchesSubtitle
      }

      return (matchesTitle || matchesSubtitle)

    })

  }, [selectedTab, searchTerm, sources])

  // if (!organizationId) {
  //   return null
  // }

  return (
    <Tabs defaultValue="all" className='h-screen pb-32' onValueChange={val => setSelectedTab(val)}>
      <div className="flex items-center px-4 py-2 h-14 space-x-6 justify-between max-w-7xl mx-auto px-4 sm:px-6 md:px-18 lg:px-8">
        <h1 className="text-xl font-bold ml-10 md:ml-0 ">Boards</h1>

        {/* <div className="relative w-full">
          <Search className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
          <Input placeholder="Search" className="pl-8" onChange={e => setSearchTerm(e.target.value)} />
        </div> */}
        <Button
          className='mx-2 h-[40px] ml-auto'
          variant="secondary"
          onClick={() => navigate({
            to: `/organizations/$organizationId/company`,
            params: { organizationId: "create" }
          })}>
          <PlusIcon className='size-5 mr-1' />
          New Board
        </Button>
        {/* <TabsList className="">
          <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">All</TabsTrigger>
          <TabsTrigger value="open" className="text-zinc-600 dark:text-zinc-200">Open</TabsTrigger>
          <TabsTrigger value="closed" className="text-zinc-600 dark:text-zinc-200">Closed</TabsTrigger>
        </TabsList> */}

      </div>
      <SelectSeparator />
      <div className="bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60  max-w-7xl mx-auto px-4 sm:px-6 md:px-18 lg:px-8">
        <div className="relative">
          <Search className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
          <Input placeholder="Search" className="pl-8" onChange={e => setSearchTerm(e.target.value)} />
        </div>

      </div>
      {(filteredItems.length === 0)
        ? <EmptyView
          title={sources?.length === 0 ? "No boards found" : "No boards found with the search criteria"}
          ctaText={sources?.length === 0 ? "Create your first board" : "Create a new board"}
          subTitle={sources?.length === 0 ? "Create your first board to get started" : "Create a new board that matches the search criteria to get started"}
          onClick={() =>
            navigate({
              to: `/organizations/$organizationId/company`,
              params: { organizationId: "create" }
            })}
        />
        : (
          <div className="h-full overflow-auto pb-12">
            <SourcesList sources={filteredItems} />
          </div>
        )
      }
    </Tabs>



  )
}


function SourcesList({ sources }: { sources: Organization[] }) {
  const { setSelectedOrganization } = useUserDetailStore()
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl  lg:max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6  px-4 sm:px-6 md:px-18 lg:px-8 ">
          {sources.map((product) => (
            <div
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white cursor-pointer w-full "
              // style={{ width: '360px' }}
              onClick={() => {
                setSelectedOrganization(product)

              }}
            >
              <div
                className="h-1"
                style={{
                  background: product?.color
                }}
              />
              <div className="w-full h-[115px] bg-gray-50 group-hover:opacity-75 flex px-4 items-center space-x-4 py-4">
                <img
                  src={product.logoUrl}
                  alt={product.name}
                  className="h-[70px] w-[70px] object-cover object-center rounded-lg"
                />
                <div className="">
                  <h3 className="text-xl font-medium text-gray-900 ">
                    {product.name}
                  </h3>
                  <p className="text-sm text-gray-600">{product?.positionCount} position{product?.positionCount === 1 ? "" : "s"} open</p>
                </div>
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">



                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500 line-clamp-3" dangerouslySetInnerHTML={{ __html: product.companyDescription }}></p>
                  <Separator className="my-4" />
                  <div className="flex w-full justify-end items-center">
                    <LinkIcon className="size-3 mr-2" />
                    <p className="text-sm text-gray-900 text-right"> <span className="font-bold">{product?.integrationName ? `${product?.integrationName}` : "Veton ATS"}</span></p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
}


function EmptyView({ onClick, title, ctaText, subTitle }: { ctaText: string, subTitle: string, title: string, onClick: () => void }) {
  return (
    <div className='w-full flex flex-col items-center justify-center my-12 space-y-8  max-w-2xl mx-auto'>
      <h1 className="text-3xl font-bold max-w-sm text-center">
        {title}
      </h1>
      <img
        src={creatingSvg}
        alt='Create a new position svg'
        style={{
          width: "40%",
          height: "auto",
        }}
      />

      <Button
        onClick={onClick}
      >
        <PlusIcon className="h-4 w-4 mr-2" />
        {ctaText}
      </Button>
      <p className='max-w-md text-center'>
        {subTitle}
      </p>

    </div>
  )
}
