import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"


import {
  Form,
  FormControl,

  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

import { Button } from "@/components/ui/button"

import { HiringMember, useAddHiringTeamMember, useRemoveHiringTeamMember } from "@/fetchers/usePosition"
import { XIcon } from "lucide-react"


import { useIsMutating } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"



import { Separator } from "@/components/ui/separator"
import { TeamMember } from "@/fetchers/useOrganization"
import AlertModal from "../../common/AlertModal"
import { useState } from "react"
import { useUserDetailStore } from "@/fetchers/useUserDetails"

const memberFormSchema = z.object({
  member: z.string().min(1, "Please select a member")
})

type MemberFormValues = z.infer<typeof memberFormSchema>

// This can come from your database or API.
const defaultValues: Partial<MemberFormValues> = {
  member: undefined,
}


export function HiringTeamForm({ availableMembers, hiringTeam }: { availableMembers: TeamMember[], hiringTeam: HiringMember[] }) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false })
  const navigate = useNavigate()
  const { selectedOrganization } = useUserDetailStore()
  // const updateFormMutation = useAIConfigformMutation()
  const isMutating = useIsMutating()

  const remove = useRemoveHiringTeamMember()
  const addMember = useAddHiringTeamMember()
  const [closeAlertModelOpen, setCloseAlertModelOpen] = useState<string | null>(null)



  const form = useForm<MemberFormValues>({
    resolver: zodResolver(memberFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  })


  async function onSubmit(data: MemberFormValues) {
    await addMember({
      organizationId: Number(organizationId),
      slug: slug,
      member: data.member
    })
    // const additionalInfo = data?.additionalQuestions?.map((question) => question.value).filter(Boolean)
    // if (!position?.slug) {
    //   return toast.error("Position is missing")
    // }
    // const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0
    // const restrictQuestions = hasAdditionalInfo && data.restrictQuestions === "enabled"
    // updateFormMutation({
    //   slug,
    //   organizationId,
    //   data: {
    //     voiceModel: data.voiceModel,
    //     additionalQuestions: additionalInfo,
    //     restrictQuestions,
    //   }
    // })

    // navigate({
    //   // to: "/new-position/$step",
    //   to: "/organizations/$organizationId/positions/manage/$slug/$step",
    //   params: {
    //     organizationId: String(organizationId),
    //     slug: slug,
    //     step: "preview"
    //   }
    // })

    form.reset({
      member: ""
    })

  }

  return (
    <>

      <AlertModal
        open={!!closeAlertModelOpen}
        onClose={() => setCloseAlertModelOpen(null)}
        title="You are about to remove a team member from the hiring team"
        body={`Team member will still have access to the position but will not be part of the hiring team and will not receive notifications.`}
        confirmText="Remove Member"
        onProceed={() => {
          if (!closeAlertModelOpen) return
          remove({
            organizationId: Number(organizationId),
            slug: slug,
            email: closeAlertModelOpen
          })
          setCloseAlertModelOpen(null)
        }}
      />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">

          <FormField
            control={form.control}
            name="member"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Add Team Members</FormLabel>
                {/* <FormDescription>
                  The location of the job
                </FormDescription> */}
                <div className="flex gap-2">
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select the position location" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="all">All Members</SelectItem>
                      {availableMembers.map(member => (
                        <SelectItem key={member.id} value={member.email}>{member.email}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Button
                    isLoading={isMutating > 0}
                    type="submit"
                    className="float-right"
                  >Add
                  </Button>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />



        </form>
      </Form>

      <div>
        <Separator className="my-8" />
        <h2 className="font-medium text-lg mb-4">Team</h2>

        <div className="space-y-2">
          {hiringTeam.map(member => (
            <div
              key={member.id}
              className="flex flex-row items-center justify-between rounded-lg border p-4 text-sm ">
              <div className="flex items-center">
                <Avatar className="mr-2 size-10">
                  <AvatarImage
                    src={member?.user?.imageUrl || ""}
                    alt={"Team member"}
                  // className="grayscale"
                  />
                  <AvatarFallback>{member?.user?.email?.slice(0, 2)?.toUpperCase()}</AvatarFallback>
                </Avatar>
                <div className="space-y-0.5">
                  <div className="text-base">
                    {member?.user?.fullName}
                  </div>
                  <div>
                    {member?.user?.email}
                  </div>
                </div>
              </div>

              <Button variant="outline" size="sm" onClick={async (event) => {
                event.preventDefault()
                // await remove({
                //   organizationId: Number(organizationId),
                //   slug: slug,
                //   email: member?.user?.email
                // })
                setCloseAlertModelOpen(member?.user?.email)
              }}>
                <XIcon className="size-5" />
              </Button>

            </div>
          ))}
        </div>
      </div>


      <Button
        isLoading={isMutating > 0}
        // type="submit"
        className="float-right"
        onClick={() => {
          navigate({
            // to: "/new-position/$step",
            to: "/organizations/$organizationId/positions/manage/$slug/$step",
            params: {
              organizationId: String(organizationId),
              slug: slug,
              step: selectedOrganization?.integrationName ? "applications" : "preview"
            }
          })
        }}
      >Next</Button>
    </>
  )
}
