import { SelectSeparator } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { NotificationForm } from "./NotificationForm";
import { Loader } from "../loader";
import { useNofitificationForm } from "@/fetchers/useUserDetails";

export function Notifications() {
  const { data, isFetching } = useNofitificationForm({})

  if (isFetching) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    )
  }

  return (
    <div className="space-y-6 p-10 pb-16 max-w-4xl mx-auto ">

      <div className='flex justify-between'>
        <div className="space-y-0.5 ">
          <h2 className="text-2xl font-bold tracking-tight ">Notifications</h2>
          <p className="text-muted-foreground">
            Configure your notifications settings to stay up-to-date on what matters.
          </p>
        </div>


      </div>

      <SelectSeparator className="my-6" />
      {/* <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0"> */}

      <div className={cn("flex-1  overflow-auto p-2  h-[calc(100vh-155px)]")}>
        {/* @ts-ignore */}
        <NotificationForm notificationForm={data} />
      </div>
      {/* </div> */}
    </div>

  )
}