import { create } from "zustand";

interface IMediaStore {
  currentPresignedUrl: string | null;
  setCurrentPresignedUrl: (url: string | null) => void;
}

export const useMediaStore = create<IMediaStore>()((set) => ({
  currentPresignedUrl: null,
  setCurrentPresignedUrl: (url) => set({ currentPresignedUrl: url }),
}));
