
import { CareersView } from '@/components/core/careers'
import { Outlet, createFileRoute, useParams } from '@tanstack/react-router'

export const Route = createFileRoute('/careers/$organizationId')({
  component: View,
})

function View() {
  // @ts-ignore
  const { jobId } = useParams({ strict: false })

  if (!jobId) return (
    <>
      <CareersView />
    </>
  )

  return (
    <>
      <Outlet />
    </>
  )
}