import { mulaw } from "alawmulaw";

const BIAS = 0x84;
const CLIP = 32635;

function linearToMuLawSample(sample: number): number {
  const sign = sample < 0 ? 0x80 : 0;
  sample = Math.abs(sample);

  if (sample > CLIP) {
    sample = CLIP;
  }

  sample += BIAS;

  let exponent = 7;
  for (
    let expMask = 0x4000;
    (sample & expMask) === 0 && exponent > 0;
    expMask >>= 1
  ) {
    exponent--;
  }

  const mantissa = (sample >> (exponent + 3)) & 0x0f;
  const muLawByte = ~(sign | (exponent << 4) | mantissa);

  return muLawByte;
}

function pcmToMuLaw(samples: Int16Array): Uint8Array {
  const muLawSamples = new Uint8Array(samples.length);

  for (let i = 0; i < samples.length; i++) {
    muLawSamples[i] = linearToMuLawSample(samples[i]);
  }

  return muLawSamples;
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function floatTo16BitPCM(
  output: DataView,
  offset: number,
  input: Float32Array
): void {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i] / 32768));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

function writeString(view: DataView, offset: number, string: string): void {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function encodeWAV(samples: Float32Array, sampleRate: number): ArrayBuffer {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  writeString(view, 0, "RIFF");
  view.setUint32(4, 32 + samples.length * 2, true);
  writeString(view, 8, "WAVE");
  writeString(view, 12, "fmt ");
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, 1, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * 2, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, "data");
  view.setUint32(40, samples.length * 2, true);

  floatTo16BitPCM(view, 44, samples);

  return buffer;
}

function convertFloat32ToPCM(input: Float32Array): Int16Array {
  const output = new Int16Array(input.length);
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
  }
  return output;
}

function bufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function decodeAudioBuffer(
  audioContext: AudioContext,
  wavData: ArrayBuffer
): Promise<AudioBuffer> {
  return audioContext.decodeAudioData(wavData);
}

function payloadToWAV(base64Audio: string): ArrayBuffer | undefined {
  const ulawData = base64ToArrayBuffer(base64Audio);

  const pcmData = mulaw.decode(new Uint8Array(ulawData));
  if (pcmData.length === 0) {
    console.error("Decoded PCM data is empty");
    return;
  }
  const wavData = encodeWAV(new Float32Array(pcmData), 8000);

  return wavData;
}

function inputBufferToMuLaw(inputBuffer: AudioBuffer): string {
  const audioData = inputBuffer.getChannelData(0);
  const pcmData = convertFloat32ToPCM(audioData);
  const muLawData = pcmToMuLaw(pcmData);
  const base64Data = bufferToBase64(muLawData);

  return base64Data;
}

export {
  linearToMuLawSample,
  pcmToMuLaw,
  base64ToArrayBuffer,
  encodeWAV,
  convertFloat32ToPCM,
  bufferToBase64,
  decodeAudioBuffer,
  payloadToWAV,
  inputBufferToMuLaw,
};
