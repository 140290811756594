import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import { Button } from "@/components/ui/button"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { toast } from "sonner"
import { Separator } from "@/components/ui/separator"
import { TApplicationForm, useUpdateApplicationFormMutation } from "@/fetchers/usePosition"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useIsMutating } from "@tanstack/react-query"


const displayFormSchema = z.object({
  name: z.enum(["required"]),
  email: z.enum(["required"]),
  phone: z.enum(["required"]),
  address: z.enum(["required", "optional", "disabled"]),
  linkedinProfile: z.enum(["required", "optional", "disabled"]),
  desiredSalary: z.enum(["required", "optional", "disabled"]),
  resume: z.enum(["required"]),
  education: z.enum(["required", "optional", "disabled"]),
  coverLetter: z.enum(["required", "optional", "disabled"]),
  additionalInfo: z.enum(["required", "optional", "disabled"]),
})

type DisplayFormValues = z.infer<typeof displayFormSchema>

// This can come from your database or API.
const defaultValues: Partial<DisplayFormValues> = {
  name: "required",
  email: "required",
  phone: "required",
  address: "disabled",
  linkedinProfile: "disabled",
  desiredSalary: "disabled",
  resume: "required",
  education: "optional",
  coverLetter: "optional",
  additionalInfo: "optional",
}


export function ApplicationForm({ applicationForm }: { applicationForm?: TApplicationForm }) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false })
  const updateApplicationForm = useUpdateApplicationFormMutation()
  const navigate = useNavigate()
  const isMutating = useIsMutating()

  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      ...defaultValues,
      ...applicationForm,
    }
  })


  async function onSubmit(data: DisplayFormValues) {
    // @ts-ignore
    const { error } = await updateApplicationForm({ data, slug, organizationId })
    if (error) {
      toast.error("Error updating application form")
      return
    }
    toast.success("Application form updated")

    navigate({
      // to: "/new-position/$step",
      to: "/organizations/$organizationId/positions/manage/$slug/$step",
      params: {
        organizationId: String(organizationId),
        slug: slug,
        step: "communication"
      }
    })

  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">


        {/* <Tabs defaultValue="account" className="w-[400px]" onValueChange={e => console.log("tabs", e)}>
          <TabsList className="grid w-full grid-cols-3" >
            <TabsTrigger value="account">Account</TabsTrigger>
            <TabsTrigger value="password">Password</TabsTrigger>
            <TabsTrigger value="google">google</TabsTrigger>
          </TabsList>
        </Tabs> */}


        <h2 className="font-bold text-xl">Personal Information</h2>

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="required">Required</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="required">Required</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      {/* <TabsTrigger value="optional">Optional</TabsTrigger> */}
                      {/* <TabsTrigger value="disabled">Disabled</TabsTrigger> */}
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Physical Address</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="linkedinProfile"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Linkedin Profile URL</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <Separator />

        <h2 className="font-bold text-xl">Experience</h2>
        <FormField
          control={form.control}
          name="resume"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Resume</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>

                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="education"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Education</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="coverLetter"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Cover Letter</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="desiredSalary"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Desired Salary</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="additionalInfo"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Additional Information</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList >
                      <TabsTrigger value="required">Required</TabsTrigger>
                      <TabsTrigger value="optional">Optional</TabsTrigger>
                      <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          isLoading={isMutating > 0}
          className="float-right" type="submit">Next</Button>
      </form>
    </Form>
  )
}
