
import { TooltipProvider } from "@/components/ui/tooltip"
import { Sidebar } from "./sidebar"
import { useState } from "react"
import {

  Menu,

} from "lucide-react"

import { Button } from "@/components/ui/button"

import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import { cn } from "@/lib/utils"
import useDims from "@/lib/useDims"
import { BillingAlert } from "../billing/BillingAlert"


interface TProps {
  defaultLayout: number[] | undefined
  defaultCollapsed?: boolean
  navCollapsedSize: number,
  children: React.ReactNode
}

export function Layout({
  defaultLayout = [265, 440, 655],
  defaultCollapsed = false,
  navCollapsedSize,
  children,
}: TProps) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  // const { RightPanelComponent, rightPanelSize } = usePanelStore()
  const { isMobile } = useDims()


  return (
    <TooltipProvider delayDuration={0}>
      <div className={cn("grid min-h-screen w-full", isCollapsed ? "md:grid-cols-[50px_1fr]" : "md:grid-cols-[220px_1fr]")}>
        <div className="hidden border-r bg-muted/40 md:block no-print">

          <Sidebar
            isCollapsed={isCollapsed}
            // isCollapsed={false}
            defaultLayout={defaultLayout}
            navCollapsedSize={navCollapsedSize}
            setIsCollapsed={setIsCollapsed}
          />

        </div>
        <div className="flex flex-col"
          style={{
            width: isMobile ? "100vw" : `calc(100vw - ${isCollapsed ? "50" : "220"}px)`,
          }}
        >

          <BillingAlert layoutClasses='p-4' />

          <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 md:hidden no-print">
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="shrink-0 md:hidden"
                >
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle navigation menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="flex flex-col max-w-xs p-0">

                <Sidebar
                  isCollapsed={false}
                  defaultLayout={defaultLayout}
                  navCollapsedSize={navCollapsedSize}
                  setIsCollapsed={setIsCollapsed}
                />
              </SheetContent>
            </Sheet>

          </header>
          <main className="flex flex-1 flex-col"

          // style={{
          //   width: "calc(100vw - 220px)",
          // }}
          >
            {children}
          </main>
        </div>
      </div>

    </TooltipProvider>
  )
}


//  {/* <Menu /> */}
//  <ResizablePanelGroup
//  direction="horizontal"
//  onLayout={(sizes: number[]) => {
//    document.cookie = `react-resizable-panels:layout=${JSON.stringify(
//      sizes
//    )}`
//  }}
//  className="h-full max-h-screen items-stretch border-t"
// >
//  <Sidebar
//    isCollapsed={isCollapsed}
//    defaultLayout={defaultLayout}
//    navCollapsedSize={navCollapsedSize}
//    setIsCollapsed={setIsCollapsed}
//  />
//  <ResizableHandle withHandle />
//  <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
//    {children}
//  </ResizablePanel>

//  {RightPanelComponent && (
//    <>
//      <ResizableHandle withHandle />
//      <ResizablePanel
//        defaultSize={rightPanelSize || defaultLayout[2]}
//        collapsible
//        collapsedSize={1}
//        minSize={30}
//        maxSize={50} >
//        <RightPanelComponent />
//      </ResizablePanel>
//    </>
//  )}

// </ResizablePanelGroup>