import { Organization } from "@/fetchers/useUserDetails"
import { CompanyForm } from "./company-form"


type TProps = {
  organization?: Organization
}
export default function CompanyFormView({ organization }: TProps) {
  return (
    <div className="space-y-6 lg:w-[48rem]  pb-[120px]">
      {/* <div>
        <h3 className="text-lg font-medium">Company Details</h3>
        <p className="text-sm text-muted-foreground">
          Setup company details for your organization and position.
        </p>
      </div> */}
      {/* <Separator /> */}
      <CompanyForm organization={organization} />
    </div>
  )
}
