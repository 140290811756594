import { Loader } from '@/components/core/loader';

import { createFileRoute, ErrorComponent } from '@tanstack/react-router'

import CompanyFormView from '@/components/core/position/company';
import { SelectSeparator } from '@/components/ui/select';
import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import { cn } from '@/lib/utils';


export const Route = createFileRoute('/organizations/$organizationId/company')({
  errorComponent: ({ error }) => {
    console.log("error @applicants page", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  },
  component: ComapanyDetailsPage,
})


function ComapanyDetailsPage() {
  const { organizationId } = Route.useParams()
  const { selectedOrganization } = useUserDetailStore()
  const { isFetching } = useUserDetails()

  if (isFetching) {
    return <Loader />
  }



  return (
    <div className="space-y-6 p-10 pb-16 max-w-7xl mx-auto">

      <div className='flex justify-between'>
        <div className="space-y-0.5 ">
          <h2 className="text-2xl font-bold tracking-tight ">Board Details</h2>
          <p className="text-muted-foreground">
            Setup your board
          </p>
        </div>


      </div>

      <SelectSeparator className="my-6" />
      {/* <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0"> */}

      <div className={cn("flex-1  overflow-auto p-2", organizationId === "create" ? "h-[calc(100vh-155px)]" : "h-[calc(100vh-180px)]")}>
        <CompanyFormView organization={selectedOrganization} />
      </div>
      {/* </div> */}
    </div>
  )
}
