import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useNavigate, useParams, } from "@tanstack/react-router"
import { RequiredStar } from "../common/RequiredStar"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { toast } from "sonner"
import { cn } from "@/lib/utils"
import PhoneInput from "react-phone-input-2"
import { useUpdateInterviewConfirmationMutation } from "@/fetchers/useInterview"


const displayFormSchema = z.object({
  phone: z.string().min(10, { message: "Please enter a valid phone number." }),
})

type UpdatePhoneFormValues = z.infer<typeof displayFormSchema>

// This can come from your database or API.
const defaultValues: Partial<UpdatePhoneFormValues> = {
  phone: undefined,
}

type TProps = {
  phone?: string,
  layoutClasses?: string
  setOpen: (open: boolean) => void
}
export function UpdatePhoneForm({ phone, layoutClasses = '', setOpen }: TProps) {
  // const update = useUpdatePhoneMutation()
  const update = useUpdateInterviewConfirmationMutation()
  // @ts-ignore
  const { interviewUid } = useParams({ strict: false })
  const navitgate = useNavigate()

  const form = useForm<UpdatePhoneFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      phone: phone || defaultValues.phone,
    },
  })



  async function onSubmit(data: UpdatePhoneFormValues) {
    try {
      if (data.phone === phone) {
        toast("No changes detected.")
        return
      }
      await update({
        interviewUid,
        phone: `+${data.phone}`,
      })

      toast("Phone number updated successfully.")
      setOpen(false)

      navitgate({
        to: `/interviews/$interviewUid/confirmation`,
        params: { interviewUid },
        search: {
          phone: `+${data.phone}`,
        },
      })

    } catch {
      toast.error("Something went wrong, please try again.")
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">

        <Card className={cn("w-full max-w-[500px]", layoutClasses)}>
          <CardHeader>
            <CardTitle>Phone number edit</CardTitle>
            <CardDescription>We will call you on this new number</CardDescription>
          </CardHeader>
          <CardContent className="">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number<RequiredStar /></FormLabel>
                  <FormControl>
                    {/* <Input placeholder="Phone Number" {...field} /> */}

                    <PhoneInput
                      value={field.value}
                      onChange={field.onChange}
                      country={"us"}
                      // onlyCountries={["us"]}
                      inputProps={{
                        className: cn(
                          `flex h-10 w-full rounded-md border border-input bg-background 
        pl-14 py-2 text-sm ring-offset-background file:border-0 
        file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
        focus-visible:outline-none focus-visible:ring-2
        focus-visible:ring-ring focus-visible:ring-offset-2 
        disabled:cursor-not-allowed disabled:opacity-50",
        className`
                        ),
                      }}
                    />

                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />




          </CardContent>
          <CardFooter className="mt-6">

            <Button
              isLoading={form.formState.isSubmitting}
              className="" type="submit">Update
            </Button>
          </CardFooter>
        </Card>










      </form>
    </Form>
  )
}
