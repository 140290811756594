import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import CreatableSelect from 'react-select/creatable';
import states from 'states-us';


import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { toast } from "sonner"

import { Input } from "@/components/ui/input"

import { RequiredStar } from "../../common/RequiredStar"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

import { countries } from "@/constants/countries"
import { Switch } from "@/components/ui/switch"
import { TPosition, useCreatePositionMutation, useUpdatePositionMutation } from "@/fetchers/usePosition";
import { useNavigate } from "@tanstack/react-router";
import { useUserDetailStore } from "@/fetchers/useUserDetails";
import { useIsMutating } from "@tanstack/react-query";
import { educationLevelValues, experienceLevelValues, typeValues } from "./constants";



const displayFormSchema = z.object({
  title: z.string({
    required_error: "Title is required",
  }),
  remote: z.boolean().default(true),
  internalId: z.string().optional(),
  // one of typeValues
  type: z.string().default(typeValues[0].value),
  location: z.string().optional().default("Worldwide"),
  state: z.string().optional(),
  city: z.string().optional(),
  educationLevel: z.string().optional(),
  experienceLevel: z.string().optional(),
  salaryMin: z.number().optional(),
  salaryMax: z.number().optional(),
  salaryCurrency: z.string().optional().default("USD"),
  salaryType: z.string().optional().default("Yearly"),
  tags: z.array(z.string()).optional(),
})

export type TDetailsForm = z.infer<typeof displayFormSchema>

// This can come from your database or API.
const defaultValues: Partial<TDetailsForm> = {
  title: undefined,
  remote: true,
  internalId: undefined,
  type: "Full-time",
  location: "Worldwide",
  state: undefined,
  city: undefined,
  educationLevel: undefined,
  experienceLevel: undefined,
  salaryMin: undefined,
  salaryMax: undefined,
  salaryCurrency: "USD",
  salaryType: "Yearly",
  tags: [],
}

export function DetailsForm({ position }: { position?: TPosition }) {
  const createPosition = useCreatePositionMutation()
  const updatePosition = useUpdatePositionMutation()
  const { selectedOrganization } = useUserDetailStore()
  const isMutating = useIsMutating()
  const navigate = useNavigate()

  const form = useForm<TDetailsForm>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      ...defaultValues,
      ...position
    },
  })

  const currentLocationValue = form.getValues().location

  const stateOptions = states.map(state => ({
    value: state.abbreviation,
    label: state.name
  }))


  async function onSubmit(payload: TDetailsForm) {
    if (!selectedOrganization?.id) return

    let response;
    if (position) {
      response = await updatePosition({
        data: payload,
        organizationId: selectedOrganization.id,
        slug: position.slug
      })

      // if (error) {
      //   toast.error("Something went wrong. Please try again.", {
      //     description: error
      //   })
      // } else {
      //   toast.success("Position updated successfully")
      // }

      // return
    } else {
      response = await createPosition({
        data: payload,
        organizationId: selectedOrganization.id
      })
    }



    const { data, error } = response

    if (data) {
      toast.success(`Position ${position ? "updated" : "created"} successfully`)
      navigate({
        // to: "/new-position/$step",
        to: "/organizations/$organizationId/positions/manage/$slug/$step",
        params: {
          organizationId: String(selectedOrganization.id),
          // @ts-ignore
          slug: data?.slug,
          step: "description"
        }
      })
    } else {
      toast.error("Something went wrong. Please try again.", {
        description: error
      })
    }

  }


  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">


        <div className="flex space-x-4">
          <FormField
            control={form.control}
            name='title'
            render={({ field }) => (
              <FormItem className="w-full">

                <FormLabel aria-required>Position Title<RequiredStar /></FormLabel>
                <FormDescription>

                </FormDescription>
                <FormControl>
                  <Input placeholder="Job Title" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />


        </div>


        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Position Type</FormLabel>
              <FormDescription>
                The type of position you are hiring for.
              </FormDescription>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select the position type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {typeValues.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>{label}</SelectItem>
                  ))}

                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="remote"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">
                  Remote available
                </FormLabel>
                <FormDescription>
                  Select if you accept remote applications
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="location"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Location</FormLabel>
              <FormDescription>
                The location of the job
              </FormDescription>
              <Select onValueChange={(value) => {
                field.onChange(value)

                form.resetField("city")
                form.resetField("state")
              }}
                defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select the position location" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Worldwide">🌎 Worldwide</SelectItem>
                  <SelectItem value="Hybrid">Hybrid</SelectItem>
                  {countries.map(country => (
                    <SelectItem key={country?.code} value={`${country?.flag} ${country?.name}`}>{country.flag} {country?.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />

        {currentLocationValue !== "Worldwide" && currentLocationValue !== "Hybrid" && (
          <div className="flex w-full gap-4">
            {currentLocationValue === "🇺🇸 United States" && (
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>State</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a state" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {stateOptions.map(({ value, label }) => (
                          <SelectItem key={value} value={value}>{label}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>City</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter city" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        <FormField
          control={form.control}
          name="educationLevel"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Education Level</FormLabel>
              <FormDescription>
                The minimum education level required for the job.
              </FormDescription>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select the education level" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {educationLevelValues.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>{label}</SelectItem>
                  ))}

                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="experienceLevel"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Experience Level</FormLabel>
              <FormDescription>
                The minimum education level required for the job.
              </FormDescription>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select experience level" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {experienceLevelValues.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>{label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center">
          <div className="flex  items-center justify-center mr-4">
            <FormField
              control={form.control}
              name='salaryMin'
              // valueAsNumber
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Salary Minimum</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      onChange={(event) => field.onChange(+event.target.value)}
                      type="number" placeholder="60000" />
                  </FormControl>
                  {/* <FormDescription>
                    In case you want to keep track of your job posts with an internal ID.
                  </FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
            <p className="text-bold px-2 mt-8">-</p>
            <FormField
              control={form.control}
              name='salaryMax'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Salary Maximum</FormLabel>
                  {/* <FormLabel>Salary max</FormLabel> */}
                  <FormControl>
                    <Input {...field}
                      onChange={(event) => field.onChange(+event.target.value)}
                      type="number" placeholder="80000" />
                  </FormControl>
                  {/* <FormDescription>
                    In case you want to keep track of your job posts with an internal ID.
                  </FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="space-x-4 items-center flex mt-7">
            <FormField
              control={form.control}
              name="salaryType"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Label</FormLabel> */}
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select experience level" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={"Yearly"}>Yearly</SelectItem>
                      <SelectItem value={"Monthly"}>Monthly</SelectItem>
                      <SelectItem value={"Hourly"}>Hourly</SelectItem>
                    </SelectContent>
                  </Select>
                  {/* <FormDescription>Description</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="salaryCurrency"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>Label</FormLabel> */}
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select experience level" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {/* <SelectItem value={"USD"}>{"USD"}</SelectItem> */}
                      {/* <SelectItem value={"EUR"}>{"EUR"}</SelectItem> */}
                      {/* @ts-ignore */}
                      {Intl?.supportedValuesOf("currency")?.map(currency => (
                        <SelectItem key={currency} value={currency}>{currency}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <FormDescription>Description</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />

          </div>
        </div>

        <FormField
          control={form.control}
          name='internalId'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Internal ID</FormLabel>
              <FormDescription>
                Optional ID to keep track of this field in your ATS or other internal systems
              </FormDescription>
              <FormControl>
                <Input {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        {/* handle tags */}
        <FormField
          control={form.control}
          name="tags"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormDescription>
                Add tags to help categorize your job post.
              </FormDescription>
              <FormControl className="remove-input-txt-border">
                <CreatableSelect
                  isClearable
                  isMulti
                  // options={field.value}
                  value={field.value?.map(tag => ({ value: tag, label: tag })) || []}
                  options={field.value?.map(tag => ({ value: tag, label: tag })) || []}
                  onChange={(tags) => {
                    // @ts-ignore
                    field.onChange(tags.map(tag => tag.value))
                  }}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />


        <Button
          isLoading={isMutating > 0}
          className="float-right" type="submit">{position ? "Update" : "Next"}</Button>
      </form>
    </Form>
  )
}
