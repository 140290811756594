import { TDetailsForm } from "@/components/core/position/details/details-form";
import { fetcher } from "@/lib/fetcher";
import { convertToCamelCase, convertToSnakeCase, handleQueryError, parseAPIError } from "@/lib/utils";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Organization } from "./useUserDetails";
import { toast } from "sonner";
import { useSearch } from "@tanstack/react-router";


export type TPosition = {
  // allowed_languages: string,
  // commitment: string,
  // content: string,
  // created_at: string,
  // display_text: string,
  // features_on: string,
  // location: string,
  // open: boolean,
  // organization: {
  //   logo_url: string,
  //   name: string,
  //   url: string,
  // },
  // origin_link: string,
  // slug: string,
  // sticky: boolean,
  // title: string,


  slug: string,
  isOpen: boolean,
  applicants: TUser[],
  organization: Organization,

  title: string,
  internalId?: string,
  remote: boolean,
  location?: string,
  state?: string,
  city?: string,
  type?: string,
  educationLevel?: string,
  experienceLevel?: string,
  salaryMin?: number,
  salaryMax?: number,
  salaryCurrency?: string,
  salaryType?: string,
  tags: string[],
  content?: string,

  createdAt: string,
  organizationSlug?: string,

  applicantCount?: number,


  // applicationForm?: TApplicationForm,
  // communicationForm?: TCommunicationForm,
}

enum EOption {
  REQUIRED = "required",
  OPTIONAL = "optional",
  DISABLED = "disabled"
}

enum ERequiredOnly {
  REQUIRED = "required",
}

export type TApplicationForm = {
  name: ERequiredOnly,
  email: ERequiredOnly,
  phone: ERequiredOnly,
  address: EOption,
  linkedinProfile: EOption,
  resume: ERequiredOnly,
  education: EOption,
  coverLetter: EOption,
  additionalInfo: EOption,
  desiredSalary: EOption,
  position: string
}
export type TCommunicationForm = {
  selectedMethod: "email" | "sms",
  afterApplication: string,
  afterInterview: string,
  position: string,
  autoSendOption?: "send_all" | "good_fit" | "maybe_and_up",

  interviewType: "video" | "phone",
  interviewDelay?: "no_delay" | "fifteen_minutes" | "thirty_minutes" | "one_hour" | "one_day" | "two_days" | "three_days",
  disqualified?: string,
  autoInterview: boolean,
}

export type TAICofigForm = {
  position: string,
  voiceModel: "echo" | "shimmer" | "nova" | "onyx",
  additionalQuestions: string[],
  restrictQuestions: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TUser = any


export function usePositionQuery({ enabled = true, position }: { enabled?: boolean, position?: string }) {
  const { getToken } = useAuth()

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['position', position],
    queryFn: async () => fetcher.get<TPosition>(`/organizations/positions/${position}`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    }).then((res) => res.data)
      .catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!enabled && !!position
  })
}


export function usePositionsQuery({ enabled = true, organizationId }: { enabled?: boolean, organizationId?: number }) {
  const { getToken } = useAuth()
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['positions', organizationId],
    queryFn: async () => fetcher.get<TPosition[]>(`/positions?organization_id=${organizationId}`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    })
      .then((res) => {
        const positions = convertToCamelCase(res.data)

        return positions as unknown as TPosition[]
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      })
    ,
    enabled: !!enabled
  })
}

export function useUpdatePositionMutation() {
  const queryClient = useQueryClient()
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ data, organizationId, slug }: { data: Partial<TPosition>, organizationId: number, slug: string }) => {

      return await fetcher
        .patch(`/positions/${slug}`, {
          data: convertToSnakeCase(data, { ignore: ["internalId"] }),
          organization_id: organizationId,
        }, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const positions = convertToCamelCase(res.data)

          return {
            data: positions as unknown as TPosition[],
            error: null
          }
        })
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")
          return {
            data: null,
            error: parseAPIError(e)
          }
        });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['positions'],
      })
    },
  });

  return mutation.mutateAsync;
}

export function useCreatePositionMutation() {
  const queryClient = useQueryClient()

  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ data, organizationId }: { data: Partial<TDetailsForm>, organizationId: number }) => {
      const { internalId, ...rest } = data

      return await fetcher
        .post(`/positions/`, {
          data: {
            ...convertToSnakeCase(rest),
            internalId
          },
          organization_id: organizationId
        }, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((r) => ({
          data: convertToCamelCase(r.data) as unknown as TPosition,
          error: null
        }))
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")

          return {
            data: null,
            error: parseAPIError(e)
          }
        });
    },
    onSuccess: () => {
      // invalidate
      queryClient.invalidateQueries({
        queryKey: ['positions'],
      })

    }
  })

  return mutation.mutateAsync;
}

export function useApplicationForm({ enabled = true, slug }: { enabled?: boolean, slug?: string }) {
  const { getToken } = useAuth()
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['application-form', slug],
    queryFn: async () => fetcher.get<TApplicationForm>(`/positions/${slug}/application-form`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    })
      .then((res) => {
        const applicationForm = convertToCamelCase(res.data)

        return applicationForm as unknown as TApplicationForm
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!enabled && !!slug
  })
}

export function useCommunicationForm({ enabled = true, slug }: { enabled?: boolean, slug?: string }) {
  const { getToken } = useAuth()
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['communication-form', slug],
    queryFn: async () => fetcher.get<TCommunicationForm>(`/positions/${slug}/communication-form`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    })
      .then((res) => {
        const applicationForm = convertToCamelCase(res.data)

        return applicationForm as unknown as TCommunicationForm
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!enabled && !!slug
  })
}

export function useUpdateCommunicationFormMutation() {
  const queryClient = useQueryClient()
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ data, organizationId, slug }: { data: Partial<TCommunicationForm>, organizationId: number, slug: string }) => {

      return await fetcher
        .patch(`/positions/${slug}/communication-form?organization_id=${organizationId}`, convertToSnakeCase(data), {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const communicationForm = convertToCamelCase(res.data)

          return {
            data: communicationForm as unknown as TCommunicationForm,
            error: null
          }
        })
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")

          return {
            data: null,
            error: parseAPIError(e)
          }
        });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['communication-form', data?.position],
      })
    },
  });

  return mutation.mutateAsync;
}

export function useUpdateApplicationFormMutation() {
  const queryClient = useQueryClient()
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ data, organizationId, slug }: { data: Partial<TApplicationForm>, organizationId: number, slug: string }) => {

      return await fetcher
        .patch(`/positions/${slug}/application-form?organization_id=${organizationId}`, convertToSnakeCase(data), {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data)

          return {
            data: applicationForm as unknown as TApplicationForm,
            error: null
          }
        })
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")

          return {
            data: null,
            error: parseAPIError(e)
          }
        });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['application-form', data?.position],
      })
    },
  });

  return mutation.mutateAsync;
}


export function useGetPublicPositions({ organizationSlug }: { organizationSlug?: string }) {
  return useQuery({
    queryKey: ['positions', organizationSlug],
    queryFn: async () => fetcher.get<TPosition[]>(`/positions/public?organization_slug=${organizationSlug}`)
      .then((res) => {
        const positions = convertToCamelCase(res.data)

        return positions as unknown as TPosition[]
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!organizationSlug
  })
}

export function useGetPublicPosition({ slug }: { slug: string }) {
  // @ts-ignore
  const { preview = false } = useSearch({ strict: false })

  return useQuery({
    retry: 0,
    queryKey: ['position', slug, preview],
    queryFn: async () => fetcher.get<TPosition & { applicationFrom: TApplicationForm }>(`/positions/public/${slug}?preview=${preview}`)
      .then((res) => {
        const position = convertToCamelCase(res.data)

        return position as unknown as TPosition & { applicationFrom: TApplicationForm }
      }).catch((error) => {
        const status = error?.response?.status?.toString()
        if (status === "404") {
          handleQueryError(error);
          return null
        } else {
          throw new Error(error);
        }
      })
  })
}


export function useAIConfigForm({ enabled = true, slug }: { enabled?: boolean, slug?: string }) {
  const { getToken } = useAuth()
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['ai-config', slug],
    queryFn: async () => fetcher.get<TAICofigForm>(`/positions/${slug}/ai-config`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    })
      .then((res) => {
        const aiConfigForm = convertToCamelCase(res.data)

        return aiConfigForm as unknown as TAICofigForm
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!enabled && !!slug
  })
}

export function useAIConfigformMutation() {
  const queryClient = useQueryClient()
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ data, organizationId, slug }: { data: Partial<TAICofigForm>, organizationId: number, slug: string }) => {

      return await fetcher
        .patch(`/positions/${slug}/ai-config?organization_id=${organizationId}`, convertToSnakeCase(data), {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data)

          return {
            data: aiConfigForm as unknown as TAICofigForm,
            error: null
          }
        })
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")

          return {
            data: null,
            error: parseAPIError(e)
          }
        });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['ai-config', data?.position],
      })
    },
  });

  return mutation.mutateAsync;
}

export type HiringMember = {
  id: string;
  user: {
    email: string;
    username: string;
    id: string;
    fullName: string;
    imageUrl: string;
  }
  // positionSlug: string;
  // role: string;
}

// Fetch hiring team members
export function useHiringTeamMembers({ enabled = true, slug, organizationId }: { enabled?: boolean; slug?: string, organizationId?: number }) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['hiring-team-members', slug, organizationId],
    queryFn: async () => {
      const token = await getToken();
      return fetcher.get<HiringMember[]>(`/positions/${slug}/hiring-team?organization_id=${organizationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          return convertToCamelCase(res.data) as HiringMember[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    enabled: !!enabled && !!slug && !!organizationId,
  });
}

// Add hiring team member
export function useAddHiringTeamMember() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({ member, organizationId, slug }: { member: string; organizationId: number; slug: string }) => {
      const token = await getToken();
      return fetcher.post(
        `/positions/${slug}/hiring-team?organization_id=${organizationId}`,
        convertToSnakeCase({ member }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return {
            data: convertToCamelCase(res.data) as HiringMember,
            error: null,
          };
        })
        .catch((error) => {
          console.log("error", error)
          toast.error("Failed to add team member");
          return {
            data: null,
            error: "Something went wrong",
          };
        });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['hiring-team-members', variables?.slug]
      });
    },
  });

  return mutation.mutateAsync;
}

// Remove hiring team member
export function useRemoveHiringTeamMember() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({ email, organizationId, slug }: { email: string; organizationId: number; slug: string }) => {
      const token = await getToken();
      return fetcher.delete(
        `/positions/${slug}/hiring-team/${email}?organization_id=${organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(() => {
          return {
            data: { success: true },
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || "Failed to remove team member");
          return {
            data: null,
            error: e?.response?.data?.detail || "An error occurred",
          };
        });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['hiring-team-members', variables?.slug]
      });
    },
  });

  return mutation.mutateAsync;
}
