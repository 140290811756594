import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from "@/components/ui/tooltip"
import logo from "@/assets/svg/logo-small.svg"
import { RequiredStar } from "./RequiredStar"

export function VetonIndicator({ hidden = false, required = false }: { hidden?: boolean, required?: boolean } = {}) {
  if (hidden) {
    return null
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger onClick={event => event.preventDefault()}>
          <div className="flex">
            <img
              alt='powered by veton.ai'
              src={logo} className="ml-1 mb-1 size-3" />

            {required && (
              <RequiredStar />
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          Veton logo indicates AI Interviewer will refer to this field
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}