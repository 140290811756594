import { Separator } from "@/components/ui/separator"
import { SidebarNav } from "./components/sidebar-nav"
import { TPosition } from "@/fetchers/usePosition"
import { useMemo } from "react"
import { useUserDetailStore } from "@/fetchers/useUserDetails"

const sidebarNavItems = (orgId: string, slug: string = "create") => [
  // {
  //   title: "Company",
  //   href: "/new-position/company",
  // },
  {
    title: "Details",
    // href: "/new-position/details",
    href: `/organizations/${orgId}/positions/manage/${slug}/details`
  },
  {
    title: "Description",
    // href: "/new-position/description",
    href: `/organizations/${orgId}/positions/manage/${slug}/description`
  },
  {
    title: "Application",
    // href: "/new-position/application",
    href: `/organizations/${orgId}/positions/manage/${slug}/application`
  },
  {
    title: "Communication",
    // href: "/new-position/communication",
    href: `/organizations/${orgId}/positions/manage/${slug}/communication`
  },
  {
    title: "AI Configuration",
    // href: "/new-position/communication",
    href: `/organizations/${orgId}/positions/manage/${slug}/ai-config`
  },
  {
    title: "Hiring Team",
    // href: "/new-position/communication",
    href: `/organizations/${orgId}/positions/manage/${slug}/hiring-team`
  },
  {
    title: "Preview",
    // href: "/new-position/preview",
    href: `/organizations/${orgId}/positions/manage/${slug}/preview`
  },
  // {
  //   title: "Profile",
  //   href: "/new-position/profile",
  // },
  // {
  //   title: "Account",
  //   href: "/new-position/account",
  // },
  // {
  //   title: "Appearance",
  //   href: "/new-position/appearance",
  // },
  // {
  //   title: "Notifications",
  //   href: "/new-position/notifications",
  // },
  // {
  //   title: "Display",
  //   href: "/new-position/display",
  // },
]

interface PositionCreationLayoutProps {
  children: React.ReactNode,
  slug?: string,
  organizationId: number,
  title: string,
  subtitle: string,
  CustomActions?: React.ReactNode[]
  position?: TPosition
}

export default function PositionCreationLayout({ children, organizationId, slug, title, subtitle, CustomActions, position }: Readonly<PositionCreationLayoutProps>) {
  const { selectedOrganization } = useUserDetailStore()

  const navItems = useMemo(() => {
    const integrationName = selectedOrganization?.integrationName
    return sidebarNavItems(String(organizationId) || "", slug).filter(item => {
      if (!integrationName) return true

      return item?.title !== "Details" && item?.title !== "Description" && item?.title !== "Application" && item?.title !== "Preview"
    })
  }, [organizationId, selectedOrganization, slug])


  return (
    <div className="space-y-6 p-5 md:p-10 w-screen md:w-full lg:pb-16 h-screen overflow-auto lg:overflow-hidden">
      <div className="space-y-0.5">
        <div className="flex justify-between items-center max-w-7xl">
          <h2 className="text-2xl font-bold tracking-tight">{title}</h2>

          <div className="flex items-end space-x-2">
            {CustomActions && CustomActions}
          </div>

        </div>
        <p className="text-muted-foreground">
          {subtitle}
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5 xl:2/5">
          <SidebarNav items={navItems} position={position} />
        </aside>
        <div className="flex-1 overflow-auto h-[85vh] p-2 ">{children}</div>
      </div>
    </div>
  )
}
