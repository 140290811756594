import { Loader } from '@/components/core/loader';
import { UserProfile } from '@clerk/clerk-react';
import DashboardPage from '@/pages/dashboard/page';
import { createFileRoute, ErrorComponent } from '@tanstack/react-router'


export const Route = createFileRoute('/user/profile')({
  errorComponent: ({ error }) => {
    console.log("error @UserProfilePage page", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  },
  component: UserProfilePage,
})


function UserProfilePage() {
  return (
    <DashboardPage>
      <div className='flex flex-col justify-center  h-screen overflow-auto pl-0 py-12 pt-6 md:pl-4'>
        <UserProfile
          appearance={{
            // elements: {
            //   userProfile
            // }
            elements: {
              rootBox: {
                height: "100%",
              },
              card: {
                boxShadow: "1px 1px 5px 0 rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
              }
            }
          }}

        />
      </div>
    </DashboardPage>
  )
}
