import { Button } from "@/components/ui/button"

import {
  Dialog,
  DialogContent,

  DialogTrigger,
} from "@/components/ui/dialog"

import { useState } from "react"
import { CreateParentOrganizationForm } from "./create-parent-organization-form"
import { GearIcon } from "@radix-ui/react-icons"
import { useOrganizationStore } from "@/fetchers/useOrganization"


export function UpdateParentOrganizationPopup() {
  const [open, setOpen] = useState(false);

  const { selectedParentOrganization } = useOrganizationStore()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button variant={"ghost"} className="p-2 hover:bg-transparent">
          <GearIcon className="size-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="">
        <CreateParentOrganizationForm
          layoutClasses='border-none'
          organization={selectedParentOrganization}
        />
      </DialogContent>
    </Dialog>
  )
}



