import { resumeVerdictValues } from "@/components/core/position/details/constants";
import { Badge } from "@/components/ui/badge";
import { useGetApplications } from "@/fetchers/useApplication";
import { useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import { create } from "zustand";

interface ICandidateStore {
  candidates: [];
  // @ts-ignore
  setCandidates: (candidates) => void;
}

export const useCandidateStore = create<ICandidateStore>()((set) => ({
  candidates: [],
  setCandidates: (candidates) => set({ candidates }),
}));

export function useCandidates({
  searchTerm,
  selectedTab,
}: {
  searchTerm: string;
  selectedTab: string;
}) {
  const { setCandidates, candidates } = useCandidateStore();
  // @ts-ignore
  const { slug } = useParams({ strict: false });
  const { data: applications, isFetching } = useGetApplications({ slug });
  useEffect(() => {
    const allItems =
      applications?.map((application) => {
        const interview = application?.interviews?.[0];
        let verdict = "";
        if (interview) {
          if (interview?.finalVerdict === "not_applicable") {
            verdict = "Completed";
          } else if (interview?.finalVerdict === "maybe_fit") {
            verdict = "Maybe Fit";
          } else if (interview?.finalVerdict === "fit") {
            verdict = "Fit";
          } else if (interview?.finalVerdict === "good_fit") {
            verdict = "Good Fit";
          } else if (interview?.finalVerdict === "great_fit") {
            verdict = "Great Fit";
          } else if (interview?.finalVerdict === "disqualified") {
            verdict = "Disqualified";
          } else if (interview?.finalVerdict === "not_fit") {
            verdict = "Not Fit";
          }
        } else {
          if (application?.resumeAnalysisVerdict) {
            verdict =
              // @ts-ignore
              resumeVerdictValues?.[
                application?.resumeAnalysisVerdict
              ]?.toUpperCase();
          }
        }

        const subtitle = ` ${application.email} | ${application.phone}`;

        const resumeBadgeColor = application?.resumeAnalysisVerdict === "interview_recommended" ? "default" : "secondary"
        const interviewBadgeColor = ["good_fit", "great_fit", "not_applicable"].includes(interview?.finalVerdict) ? "default" : "secondary"
        // TODO: Fix this logic to have interviewBadgeColor have a higher priority
        let badgeColor = resumeBadgeColor === "default" || interviewBadgeColor === "default" ? "default" : "secondary"

        if (interview?.startedAt && !interview?.completedAt) {
          verdict = "In Progress";
          badgeColor = "default"
        }

        return {
          slug: application?.id,
          title: `${application?.name || ""} ${verdict ? `| ${verdict}` : ""}`,
          titleJSX: () => (
            // @ts-ignore
            <p>{application?.name} {verdict && <span><Badge variant={badgeColor}>{verdict?.toUpperCase()}</Badge></span>}</p>
          ),


          completedAt: interview?.completedAt,
          subtitle: subtitle,
          // tags: [interview?.assistantType || ""],
          content: `<p>${interview?.analysisSummary || ""}</p>`,

          applicationId: application?.id,
          user: application?.user,
          item: application,
          startedAt: interview?.startedAt,
          archived: application?.archived || false,
        };
      }) || [];

    const filtered = allItems.filter((item) => {
      const matchesTitle =
        item.title.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesSubtitle =
        item.subtitle.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      // const matchesContent = item.content.toLowerCase().split(searchTerm.toLowerCase()).length > 1

      if (selectedTab === "all") {
        return matchesTitle || matchesSubtitle;
      }

      return matchesTitle || matchesSubtitle;
    })
    setCandidates(filtered);
  }, [selectedTab, applications, searchTerm, isFetching]);

  return {
    candidates,
  };
}
