import { ApplicationForm } from "./application-form"
import { cn, getContrastColorFromBackgroundColor, getSubtitleFromPosition, getIsBright, getDominantColor } from "@/lib/utils"

import { Separator } from "@/components/ui/separator"
// import { Button } from "@/components/ui/button"
import { TApplicationForm, TPosition } from "@/fetchers/usePosition"
import { Organization } from "@/fetchers/useUserDetails"
import { Loader } from "../../loader"
import logo from "@/assets/svg/logo-whitebg.svg"
import { ShareIcon } from '@heroicons/react/24/outline'

import SocialSharePanel from "../../common/SocialSharePanel"
import { useState } from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import useDims from "@/lib/useDims"
import { Button } from "@/components/ui/button"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"
import { Tabs, TabsContent } from "@/components/ui/tabs"


function Header({ logo, title, organizationUrl, setCurrentTab, positionTitle, currentTab }: { currentTab: string, positionTitle: string, logo: string, title: string, organizationUrl?: string, setCurrentTab: (tab: string) => void }) {
  const { isMobile } = useDims()
  const [shareModalOpen, setShareModalOpen] = useState(false);
  return (
    <>
      <SocialSharePanel
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        title={"Share this job!"}
        shareUrl={window.location.href}
      />
      <div className="w-full sticky top-0 z-10 mt-6 mb-4 lg:mt-4 bg-white">
        <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row  ">

          <div className="flex  items-center justify-center">

            <div className="rounded overflow-hidden size-[80px] flex items-center justify-center shrink-0">
              <img src={logo}
                // className="object-fit"
                alt="Company Logo"
                style={{
                  width: "60px",
                  height: '60px',
                  // objectFit: "fill",
                  aspectRatio: 1
                }}
              />
            </div>


            <div className="flex  flex-col ml-4">
              <p className="font-bold text-2xl">{title}</p>
              <Breadcrumb >
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink href="/">Job Openings</BreadcrumbLink>
                  </BreadcrumbItem>
                  {currentTab === "application-form" && (
                    <>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem className="cursor-pointer">
                        <BreadcrumbLink onClick={() => setCurrentTab("content")}>{positionTitle}</BreadcrumbLink>
                      </BreadcrumbItem>
                    </>
                  )}
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>{currentTab === "content" ? positionTitle : "Apply"}</BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>

            </div>
          </div>


          <div className="text-center mt-2">
            {organizationUrl && (
              <a href={organizationUrl} target="__blank">
                <Button
                  variant={isMobile ? "link" : "secondary"}>Company Website</Button>
              </a>
            )}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    onClick={() => setShareModalOpen(true)}
                    className="flex items-center">
                    Share
                    <ShareIcon
                      className={cn('size-5 ml-2 text-gray-600 cursor-pointer')}
                    />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Share this job</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
    </>
  )
}



function Content({ description, setCurrentTab }: { description: string, setCurrentTab: (tab: string) => void }) {
  return (
    <div className="overflow-y-auto w-full h-full w-full mx-auto max-w-3xl">

      <p className="w-full prose " dangerouslySetInnerHTML={{ __html: description }}>

      </p>

      <div className="p-12 flex items-center justify-center">
        <Button
          onClick={() => setCurrentTab("application-form")}
          className="text-xl p-6">Apply</Button>
      </div>
    </div>
  )
}

function ApplicationFormView({ config, position, themeColor }: { config: TApplicationForm, position: TPosition, themeColor?: string }) {
  return (
    <div
      id='application-form'
      style={{
        height: "fit-content"
      }}
      className={cn(
        "rounded-lg p-4 ",
        "block border border-2",
        "sm:shadow-xl   lg:border-0  max-w-2xl mx-auto",
        "lg:overflow-auto",
        "mb-12"
      )}>
      <ApplicationForm config={config} position={position} themeColor={themeColor} />
    </div>
  )
}

type TProps = {
  position?: TPosition & { applicationForm: TApplicationForm },
  organization?: Organization
}
export function CareerView({ position, organization }: TProps) {
  const [currentTab, setCurrentTab] = useState("content")

  if (!position || !organization) {
    return (
      <Loader />
    )
  }

  return (

    <div className="flex flex-col mx-auto  overflow-y-auto h-screen overflow-x-hidden w-screen">
      <Header
        logo={organization.logoUrl || ""}
        title={organization.name}
        setCurrentTab={setCurrentTab}
        positionTitle={position.title}
        currentTab={currentTab}
      />

      <div className="flex flex-col items-center justify-center min-h-[max(300px,40vh)] space-y-6 p-8 text-center"
        style={{
          background: organization?.color,
          color: getContrastColorFromBackgroundColor(organization?.color)
        }}
      >
        {/* <p className="font-bold text-3xl md:text-4xl">Join {organization?.name || "us"} !</p> */}
        <p className="font-bold text-2xl lg:text-4xl max-w-3xl">{position?.title} at <span >{organization?.name}</span></p>
        <p className="text-xl">{getSubtitleFromPosition(position)}</p>

        <Button
          variant={getIsBright(getDominantColor(organization?.color)) ? "default" : "secondary"}
          onClick={() => {
            setCurrentTab("application-form")

            setTimeout(() => {
              const openings = document.getElementById('application-form')
              openings?.scrollIntoView({ behavior: 'smooth' })
            }, 120)
          }}
          className="md:text-lg p-6">Apply</Button>
      </div>




      <Tabs defaultValue="content" value={currentTab}>
        <div className='flex flex-col justify-between mt-12'>
          <div className="flex flex-1 flex-col   relative px-2 sm:px-8 justify-between space-y-4  h-full">

            <TabsContent value="content">
              <Content description={position?.content || ""} setCurrentTab={setCurrentTab} />
            </TabsContent>
            <TabsContent value="application-form">
              <ApplicationFormView config={position.applicationForm} position={position} themeColor={organization?.color} />
            </TabsContent>

          </div>

          <Separator />
          <a
            href="https://veton.ai"
            target="_blank"
            className="p-4 text-lg flex justify-center items-center h-[60px] sticky bottom-0 left-0 w-full bg-white border-t border-gray-100 z-10 bg-white ">
            Powered by <img
              alt='powered by veton.ai'
              src={logo} style={{ height: 25 }} className="ml-1" />
          </a>
        </div>
      </Tabs>
    </div >
  )
}



