import { cn, getContrastColorFromBackgroundColor, getOrganizationSlugIfSubdomain, getSubtitleFromPosition } from "@/lib/utils"

import { Separator } from "@/components/ui/separator"

import { ItemList } from "@/pages/dashboard/components/item-list"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useGetPublicOrganization } from "@/fetchers/useOrganization"
import { Loader } from "../loader"
import { useGetPublicPositions } from "@/fetchers/usePosition"
import logo from "@/assets/svg/logo-whitebg.svg"

import { Button } from "@/components/ui/button"
import useDims from "@/lib/useDims"
import { ErrorView } from "../ErrorView"




type THeaderProps = Readonly<{
  logo: string,
  title: string,
  organizationUrl?: string,
  openPositionCount: number
}>

function Header({ logo, title, organizationUrl, openPositionCount = 0 }: THeaderProps) {
  const { isMobile } = useDims()
  return (
    <div className="w-full sticky top-0 z-10 mt-6 mb-4 lg:mt-4 bg-white">
      <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row ">

        <a className={
          cn("flex  items-center justify-start w-full ")
        }
          role={!organizationUrl ? "div" : undefined}
          style={{
            cursor: organizationUrl ? "pointer" : "default"
          }}
          target="__blank"
          href={
            organizationUrl ?? ""
          }

        >

          <div className="rounded overflow-hidden size-[60px] ">
            <img src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                width: "60px",
                height: '60px',
                // objectFit: "fill",
                aspectRatio: 1,
                cursor: organizationUrl ? "pointer" : "default"
              }}
            />
          </div>


          <div className="flex  flex-col ml-4 text-left">
            <p className="font-bold text-2xl">{title}</p>
            <p className="text-sm text-gray-700">{openPositionCount} open positions</p>
          </div>
        </a>


        <div className="text-center mt-2">
          {organizationUrl && (
            <a href={organizationUrl} target="__blank">
              <Button
                variant={isMobile ? "link" : "secondary"}>Company Website</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}



function Content({ description }: { description: string }) {
  return (
    <div className="overflow-y-auto w-full  pb-12 text-center"
      style={{
        height: "fit-content"
      }}
    >
      <p className="whitespace-pre-line w-full prose mx-auto"
        dangerouslySetInnerHTML={{ __html: description }}>
      </p>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ApplicationFormView({ items, organzationSlug, organzationName }: { items: any, organzationSlug?: string, organzationName?: string }) {
  const navigate = useNavigate()
  const isSubdomain = getOrganizationSlugIfSubdomain()

  // if (items.length !== 0) {
  //   return (<div>
  //     No Open Positions Available
  //   </div>
  //   )
  // }


  return (
    <div
      id='application-form'
      style={{
        height: "fit-content",
        width: "100%",
      }}
      className={cn(
        "rounded-lg lg:p-4 ",
        " lg:border-0  ",
        " overflow-hidden h-full",
        "mx-auto",
        "max-w-4xl",
        // "lg:overflow-auto",
      )}>

      <div className="text-center mb-12">
        <p className="text-2xl font-bold">Our Openings</p>
        <p className="text-gray-700">{items?.length || 0} Open Position{items?.length === 1 ? "" : "s"}</p>
      </div>
      <ItemList
        layoutClasses=""
        onClick={item => {
          if (isSubdomain) {
            navigate({
              to: "/$jobId",
              params: { jobId: item?.slug }
            })
          } else {
            navigate({
              to: `/careers/$organizationId/$jobId`,
              params: { organizationId: `${organzationSlug}`, jobId: item?.slug }
            })
          }

        }}
        items={items && items.length > 0 ? items : [{
          slug: "slug",
          title: `${organzationName || ""} Positions`,
          subtitle: "Currently no open positions available",
          content: "",
          disabled: true
        }]}
      />
    </div>
  )
}

export function CareersView() {
  // @ts-ignore
  const { organizationId } = useParams({ strict: false })

  const organizationSlug = getOrganizationSlugIfSubdomain()


  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug: organizationSlug || organizationId
  })


  const { data: positions } = useGetPublicPositions({ organizationSlug: organization?.slug })
  // fetch public positions
  // fetch public organization details

  if (isFetching) {
    return (
      <Loader />
    )
  }

  if (!organization) {
    return (
      <ErrorView />
    )
  }



  const items = positions?.map(position => ({
    slug: position?.slug,
    title: position?.title,
    createdAt: position?.createdAt,
    subtitle: getSubtitleFromPosition(position),
    tags: position.tags,
    content: position.content || "",
  })) || []


  return (

    <div className="flex flex-col mx-auto  overflow-y-auto h-screen overflow-x-hidden w-screen">
      <Header
        logo={organization?.logoUrl ?? ""}
        title={organization?.name ?? ""}
        openPositionCount={items?.length}
        organizationUrl={organization?.parentOrganizationWebUrl}
      />

      <div className="flex flex-col items-center justify-center min-h-[40vh] h-[40vh] space-y-6 p-8 text-center"
        style={{
          background: organization?.color,
          color: getContrastColorFromBackgroundColor(organization?.color)
        }}
      >
        <p className="font-bold text-3xl md:text-4xl">Join {organization?.name || "us"}!</p>
        <Button
          onClick={() => {
            const openings = document.getElementById('application-form')
            openings?.scrollIntoView({ behavior: 'smooth' })
          }}
          className="md:text-xl p-6">View Openings</Button>
      </div>



      <div className='flex flex-col justify-between mt-12'>

        <Content
          description={organization?.companyDescription || ""}
        />
        <ApplicationFormView items={items} organzationSlug={organization?.slug} organzationName={organization?.name} />


        <Separator />
        <a
          href="https://veton.ai"
          target="_blank"
          className="p-4 text-lg flex justify-center items-center h-[60px] sticky bottom-0 left-0 w-full bg-white border-t border-gray-100">
          Powered by <img
            alt='powered by veton.ai'
            src={logo} style={{ height: 25 }} className="ml-1" />
        </a>
      </div>


    </div>


  )
}






