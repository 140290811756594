import { createFileRoute } from '@tanstack/react-router'
import DashboardPage from '@/pages/dashboard/page';
import { TeamSettings } from '@/components/core/team-settings';
import { Separator } from '@/components/ui/separator';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { InviteDialog } from '@/components/core/team-settings/InviteModal';
import { useUserDetails } from '@/fetchers/useUserDetails';
import { useGetMemberships, useOrganizationStore } from '@/fetchers/useOrganization';
import { Loader } from '@/components/core/loader';
import { ParentOgranizationCreationView } from '@/components/core/parent-organization';




export const Route = createFileRoute('/team-management')({
  component: TeamManagementComponent
})

function TeamManagementComponent() {
  const [searchTerm, setSearchTerm] = useState('')

  const { data: userDetails, isFetching: isFetchingUserDetails } = useUserDetails()
  const { data: memberships, isFetching: isFetchingMemberships } = useGetMemberships({ enabled: !isFetchingUserDetails && !!userDetails?.id })
  const { selectedParentOrganization } = useOrganizationStore()

  if (isFetchingMemberships || isFetchingUserDetails) {
    return <Loader />
  }

  if (!memberships || memberships.length === 0) {
    return (
      <ParentOgranizationCreationView />
    )
  }


  return (
    <DashboardPage>
      <div className="space-y-6 p-5 md:p-10 w-screen md:w-full lg:pb-16 h-screen overflow-auto lg:overflow-hidden max-w-5xl mx-auto">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Team Settings</h2>
          <p className="text-muted-foreground">
            Manage your team members and their roles.
          </p>
        </div>
        <Separator className="my-6" />

        <div className='flex justify-between items-center'>
          {selectedParentOrganization?.memberRole === "admin" && (
            <InviteDialog />
          )}

          <div className="relative w-full max-w-xs">
            <Search className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
            <Input placeholder="Search" className="pl-8" onChange={e => setSearchTerm(e.target.value)} />
          </div>
        </div>

        <div className='pt-4'>
          <TeamSettings searchTerm={searchTerm} />
        </div>
      </div >


    </DashboardPage >
  )
}


