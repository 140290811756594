import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Cog } from "lucide-react"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { cn } from "@/lib/utils"
import { memo, useState } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SettingsDialogRaw({ webcamManager, variant }: any) {
  const [selectedDevices, setSelectedDevices] = useState<{
    audio?: string;
    video?: string;
  }>({
    audio: undefined,
    video: undefined,
  })


  return (
    <Dialog onOpenChange={(open) => {
      if (!open) {
        setSelectedDevices({
          audio: undefined,
          video: undefined,
        })
      }
    }}>
      <DialogTrigger asChild>
        <Button
          variant={variant === "icon" ? "ghost" : "outline"}
          className={
            cn(variant === "icon" && "hover:bg-transparent")
          }
          onClick={() => {
            // check if full screen
            if (document.fullscreenElement) {
              document.exitFullscreen()
            }
          }}
        >
          <Cog className={cn("size-5", variant === 'icon' ? "text-white hover:text-black" : "mr-2")} />
          {variant !== "icon" && "Settings"}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Input Devices</DialogTitle>
          <DialogDescription>
            Select the audio and video devices you want to use for the interview
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-6 py-4 ">
          <div className="space-y-2">
            <Label>Audio devices</Label>

            <SelectComponent
              deviceId={selectedDevices?.audio ?? webcamManager?.audioDeviceId}
              items={webcamManager?.devicesByType?.audio || []}
              dataset="deviceid"
              // onChange={webcamManager?.handleSelect}
              onChange={(value) => {
                setSelectedDevices({ ...selectedDevices, audio: value })
              }}
            />

          </div>
          <div className="space-y-2">

            <Label>Video devices</Label>
            <SelectComponent
              deviceId={selectedDevices?.video ?? webcamManager?.videoDeviceId}
              items={webcamManager?.devicesByType?.video || []}
              dataset="deviceid"
              // onChange={webcamManager?.handleSelect}
              onChange={(value) => {
                setSelectedDevices({ ...selectedDevices, video: value })
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                if (selectedDevices.audio) {
                  webcamManager?.handleSelect(selectedDevices.audio)
                }
                if (selectedDevices.video) {
                  webcamManager?.handleSelect(selectedDevices.video)
                }
              }}
              type="submit">Save changes</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type SelectProps = {
  deviceId: string;
  items: { label: string; deviceId: string }[];
  dataset: string;
  onChange: (value: string) => void;
};

function SelectComponent(props: SelectProps) {
  if (!props.deviceId) {
    return (
      null
    )
  }

  return (
    <Select value={props.deviceId} onValueChange={props.onChange}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select your device" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup >
          <SelectLabel>Devices</SelectLabel>

          {props.items.map((item) => {
            const dataAttr = {
              [`data-${props.dataset}`]: item.deviceId,
            };
            return (
              <SelectItem
                key={item.deviceId}
                value={item.deviceId}
                {...dataAttr}>
                {item.label}
              </SelectItem>

            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export const SettingsDialog = memo(SettingsDialogRaw)