import { fetcher } from "@/lib/fetcher";
import { convertToCamelCase, convertToSnakeCase, handleQueryError } from "@/lib/utils";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { create } from "zustand";
import { toast } from "sonner";


export type Organization = {
  createdAt: string
  id: number
  logoUrl: string
  name: string
  updatedAt: string
  url: string
  color: string
  companyDescription: string
  companyReachOutEmail?: string
  slug?: string
  positionCount: number
  parentOrganizationWebUrl?: string
  integrationName?: string
}

export type UserDetails = {
  email: string
  firstName: string
  id: number
  lastName: string
  username: string
  // organizations: Organization[]
}

export const fetchUserDetails = async (token: string | null) => {
  console.log('Fetching user details...')

  return fetcher
    .get<UserDetails>('users/details', {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    }).then((res) => convertToCamelCase(res.data) as UserDetails)
    .catch((error) => {
      handleQueryError(error);
      throw new Error(error);
    })
}


export type TNotificationForm = {
  applyingCandidates: "none" | "all"
  resumeAnalysisResults: "none" | "good_fits_only" | "all"
  interviewStarted: "none" | "good_fits_only" | "all"
  interviewAnalysisResults: "none" | "good_fits_only" | "all"

}
export function useNofitificationForm({ enabled = true }: { enabled?: boolean }) {
  const { getToken, userId } = useAuth()


  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['notification-form', userId],
    queryFn: async () => fetcher.get<TNotificationForm>(`/users/notification-settings`, {
      headers: {
        "Authorization": `Bearer ${await getToken()}`,
      },
    })
      .then((res) => {
        const applicationForm = convertToCamelCase(res.data)

        return applicationForm as unknown as TNotificationForm
      }).catch((error) => {
        handleQueryError(error);
        throw new Error(error);
      }),
    enabled: !!enabled,
    retry: 1
  })
}


export function useUpdateNotificationSettingsMutation() {
  const queryClient = useQueryClient()
  const { getToken, userId } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({ data }: { data: Partial<TNotificationForm> }) => {

      return await fetcher
        .patch(`/users/notification-settings`, convertToSnakeCase(data), {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data)

          return {
            data: applicationForm as unknown as TNotificationForm,
            error: null
          }
        })
        .catch((e) => {

          toast.error(e.response.data.detail || "Something went wrong")

          return {
            data: null,
            // @ts-expect-error msg might not exist
            error: e?.response?.data?.detail?.map((e: string) => e?.msg).join(", ")
          }
        });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notification-settings', userId],
      })
    },
  });

  return mutation.mutateAsync;
}

export function useUserDetails(props: { enabled?: boolean } = { enabled: true }) {
  const { getToken } = useAuth()
  // const { selectedOrganization, setSelectedOrganization } = useUserDetailStore()
  // const { selectedMembership, setSelectedMembership } = useUserDetailStore()
  // const navigate = useNavigate()

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['user-details'],
    queryFn: async () => {
      const details = await fetchUserDetails(await getToken())
      // if (!selectedMembership) {
      //   setSelectedMembership(details?.memberships?.[0])
      // }
      return details
    },
    enabled: !!props?.enabled
  })
}


export const useUserDetailStore = create<{
  // selectedMembership?: Membership,
  // setSelectedMembership: (membership?: Membership) => void

  selectedOrganization?: Organization,
  setSelectedOrganization: (organization?: Organization) => void
}>()((set) => ({
  // selectedMembership: undefined,
  // setSelectedMembership: (membership?: Membership) => set({ selectedMembership: membership }),

  selectedOrganization: undefined,
  setSelectedOrganization: (organization?: Organization) => set({ selectedOrganization: organization })
}))



