
import AuthenticationPage from "@/pages/authentication/page";
import { SignIn } from "@clerk/clerk-react";
import { ErrorComponent, createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchSchema = z.object({
  redirect_url: z.string().optional()
})


export const Route = createFileRoute('/signin')({
  component: SignInPage,
  validateSearch: searchSchema,
  errorComponent: ({ error }) => {
    console.log("error @signin", error)
    return <ErrorComponent error={error} />;
  }
})


function SignInPage() {
  const { redirect_url } = Route.useSearch()

  return (
    <AuthenticationPage>
      <SignIn
        path="/signin"
        signUpUrl="/signup"
        redirectUrl={redirect_url || "/organizations"}
        appearance={{
          // layout: {
          //   termsPageUrl: "/terms",
          //   privacyPageUrl: "/privacy",
          // },
          variables: { colorPrimary: 'black' },

          // elements: {
          //   footer: {
          //     display: 'none',
          //   },
          // }
        }} />
    </AuthenticationPage>
  )
}

