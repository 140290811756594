import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { fetcher } from "@/lib/fetcher";
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
} from "@/lib/utils";
import { toast } from "sonner";

type TGreetUser = {
  token: string | null;
  interviewId: number;
  language: string;
};
export const greetUser = async ({
  token,
  interviewId,
  language,
}: TGreetUser) => {
  console.log("Posting greet...");

  return fetcher
    .post<{
      // answer: string;
      audioDataBase64: string;
      presignedUrl?: string;
    }>(
      `/applications/interviews/${interviewId}/greet`,
      {
        language,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(
      (r) =>
        convertToCamelCase(r.data) as unknown as {
          audioDataBase64: string;
          presignedUrl?: string;
        }
    )
    .catch((error) => {
      handleQueryError(error);
      throw new Error(error);
    });
};

export function useGreetMutation() {
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      interviewId,
      language,
    }: {
      interviewId: number;
      scenario: string;
      language: string;
    }) =>
      await greetUser({
        token: await getToken(),
        interviewId,
        language,
      }),
  });

  return mutation.mutateAsync;
}

type TAudioQueryIn = {
  token: string | null;
  interviewId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
};
type TAudioQueryOut = {
  transcript: string;
  answer: string;
  audioDataBase64: string;
  presignedUrl: string;
};
export const audioQuery = async ({
  token,
  interviewId,
  formData,
}: TAudioQueryIn) => {
  return fetcher
    .post<TAudioQueryOut>(
      `/applications/interviews/${interviewId}/audio/query`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => convertToCamelCase(r.data) as unknown as TAudioQueryOut)
    .catch((error) => {
      handleQueryError(error);
      throw new Error(error);
    });
};

export function useAudioQueryMutation() {
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      interviewId,
      formData,
    }: {
      interviewId: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: any;
    }) =>
      await audioQuery({
        token: await getToken(),
        interviewId,
        formData,
      }),
  });

  return mutation.mutateAsync;
}

export function useCreateInterviewMutation() {
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      sessionId,
      scenario,
    }: {
      sessionId: number;
      scenario: string;
    }) =>
      await fetcher
        .post(
          `/sessions/${sessionId}/interview/create`,
          {
            type: scenario,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => r.data)
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
  });

  return mutation.mutateAsync;
}

export function useDisqualifyCandidateMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ applicationId }: { applicationId: number }) =>
      await fetcher
        .patch(
          `/admin/applications/${applicationId}/disqualify`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data)),
    onSuccess: ({ applicationId, positionSlug }) => {
      queryClient.invalidateQueries({
        queryKey: ["applications", "admin", positionSlug],
      });
      queryClient.invalidateQueries({
        queryKey: ["application", "admin", applicationId],
      });
    },
    onError: (e) => {
      toast.error(e?.message || "Something went wrong");

      return {
        data: null,
        // @ts-expect-error msg might not exist
        error: parseAPIError(e),
      };
    },
  });

  return mutation.mutateAsync;
}

export function useUpdateInterviewMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      interviewId,
      payload,
    }: {
      interviewId: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
    }) =>
      await fetcher
        .patch(
          `/admin/applications/interviews/${interviewId}`,
          convertToSnakeCase(payload),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data)),
    onSuccess: ({ applicationId, positionSlug }) => {
      queryClient.invalidateQueries({
        queryKey: ["applications", "admin", positionSlug],
      });
      queryClient.invalidateQueries({
        queryKey: ["application", "admin", applicationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export type InterviewQuery = {
  // analysis: string;
  // completed_at: string | null;
  // created_at: string;
  // id: number;
  // openai_thread_id: string;
  // session: number;
  // started_at: string | null;
  // status: string | null;
  // transcript: string | null;
  // type: string;
  // updated_at: string;

  id: number;
  uuid: string;
  startedAt: string | null;
  completedAt: string | null;
  assistantType: string | null;
  interviewType: string | null;
  userName: string | null;
  userEmail: string | null;

  title: string | null;
  voiceModel: string | null;

  openaiThreadId: string | null;
  openaiAssistantId: string | null;

  hasResume: boolean;

  organization: {
    id: number;
    slug: string;
    name: string;
    logoUrl: string;
    color: string;
  };
};
export function useInterviewQuery({
  interviewUUid,
}: {
  interviewUUid?: string;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["interview", interviewUUid],
    queryFn: async () =>
      await fetcher
        .get<InterviewQuery>(`/applications/interviews/${interviewUUid}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((r) => convertToCamelCase(r.data) as unknown as InterviewQuery)
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!interviewUUid,
    retry: 0,
  });
}

export function useFinishInterviewMutation() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ interviewUUID }: { interviewUUID: string }) =>
      await fetcher
        .post(
          `/applications/interviews/${interviewUUID}/finish`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data) as unknown as InterviewQuery)
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    onMutate: async ({ interviewUUID }) => {
      queryClient.setQueryData(
        ["interview", interviewUUID],
        (old: InterviewQuery) => ({
          ...old,
          completedAt: new Date().toISOString(),
        })
      );
    },
  });

  return mutation.mutateAsync;
}

export type TInterview = {
  analysis: string | null;
  completed_at: string | null;
  created_at: string;
  started_at: string | null;
  status: string | null;
  type: string | null;
  updated_at: string;
};
type TSession = {
  created_at: string;
  id: number;
  slug: string | null;
  updated_at: string;
  name: string;
  interviews: TInterview[];
};
export type TMember = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  sessions: TSession[];
};
export function usePositionInterviewsQuery({
  organizationId,
  enabled,
}: {
  organizationId: number;
  enabled: boolean;
}) {
  // const { data: members = [] } = await requestFrom("/organizations/positions/interviews", { token })
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ["position-interviews", organizationId],
    queryFn: async () =>
      await fetcher
        .get<TMember[]>(`/organizations/positions/interviews`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((r) => r.data)
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled,
  });
}

export type TInterviewConfirmation = {
  id: number;
  organization: {
    logoUrl: string;
    name: string;
    url: string;
    color: string;
    companyReachOutEmail?: string;
    slug?: string;
  };
  positionName: string;
  hasResume: boolean;
};

export function useGetInterviewConfirmationPageDetails({
  interviewUUid,
}: {
  interviewUUid?: string;
}) {
  return useQuery({
    queryKey: ["interview-confirmation", "details", interviewUUid],
    queryFn: async () => {
      const url = `/applications/interviews/${interviewUUid}/details`;

      return fetcher
        .get<TInterviewConfirmation>(url)
        .then((res) => {
          const organization = convertToCamelCase(res.data);

          return organization as unknown as TInterviewConfirmation;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },

    enabled: !!interviewUUid,
  });
}

export function useConfirmationPageStartInterview({
  enabled,
  interviewUid,
}: {
  interviewUid: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ["interview-confirmation", "start", interviewUid],
    queryFn: async () =>
      await fetcher
        .get(`/applications/interviews/${interviewUid}/start`)
        .then((r) => r.data)
        .catch((error) => {
          const detail = error?.response?.data?.detail;
          console.log("detail", detail);
          if (detail) {
            toast.error("Something went wrong!", {
              description: detail,
              position: "top-right",
            });
          }
          return error?.response?.data?.detail;
        }),
    retry: 0,
    enabled,
  });
}

export function useUpdateInterviewConfirmationMutation() {
  const mutation = useMutation({
    mutationFn: async ({
      phone,
      interviewUid,
      resume,
    }: {
      phone?: string;
      resume?: File;
      interviewUid: number;
    }) => {
      const formData = new FormData();
      if (phone) {
        formData.append("phone", phone);
      }

      if (resume) {
        formData.append("resume", resume);
      }

      return await fetcher
        .post(`/applications/interviews/${interviewUid}/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((r) => convertToCamelCase(r.data))
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useResetInterviewMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({ interviewId }: { interviewId: number }) =>
      await fetcher
        .post(
          `/admin/applications/interviews/${interviewId}/reset`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data)),
    onSuccess: ({ applicationId, positionSlug }) => {
      queryClient.invalidateQueries({
        queryKey: ["applications", "admin", positionSlug],
      });
      queryClient.invalidateQueries({
        queryKey: ["application", "admin", applicationId],
      });
    },
    onError: (e) => {
      toast.error(e?.message || "Something went wrong");

      return {
        data: null,
        // @ts-expect-error msg might not exist
        error: parseAPIError(e),
      };
    },
  });

  return mutation.mutateAsync;
}
