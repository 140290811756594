
import { ButtonProps } from "@/components/ui/button"
import { ConnectionDialog } from "./ConnectionDialog"

type TProps = {
  variant?: ButtonProps["variant"]
}
export function ATSConnectionDialog({ variant = "default" }: Readonly<TProps>) {
  return (
    <ConnectionDialog variant={variant} />
  )
}