import { cn, getAvatarNameFromModel, getContrastColorFromBackgroundColor, getDominantColor } from "@/lib/utils"
import AlertModal from "../common/AlertModal"
import { useEffect, useRef, useState } from "react";
// import { useVocalConversation } from "@/fetchers/useVocalConversation";
import { Spinner } from "../common/Spinner";
import { Button } from "@/components/ui/button";
import { InterviewQuery, useFinishInterviewMutation } from "@/fetchers/useInterview";


import { LogOutIcon, Mic, User2Icon, Video, VideoOff } from "lucide-react"
import Webcam from "react-webcam";
import { PreInterviewComponent } from "../interview/PreInterview";

import Lottie from 'react-lottie';
import lottieData from '@/assets/lottie/audio_waves.json'
import AlexAvatar from '@/assets/images/alex_avatar.png'
import AliceAvatar from '@/assets/images/alice_avatar.png'
import EmmaAvatar from '@/assets/images/emma_avatar.png'
import JamesAvatar from '@/assets/images/james_avatar.png'

//@ts-ignore
import VetonLogo from '@/assets/svg/logo-small.svg?react'
import { SettingsDialog } from "../interview/SettingsDialog";
import { useInterviewTimeElapsedCounter } from "@/lib/useInterviewTimeElapsedCounter";
// import { useWebcamRecorder } from "@/lib/useMediaHanlder/useWebcamRecorder";
// import { useMediaRecorder } from "@/lib/useMediaHanlder/useMediaRecorder";
import { useVocalConversation } from "@/fetchers/useVocalConversation";

type TProps = {
  data?: InterviewQuery
  startStream: () => void
  aiTalking?: boolean
  endStream: () => void
  generalApplicationData?: {
    assistantType: string
    openaiAssistantId: string
    openaiThreadId: string
    voiceModel: string
  }
}
export function Interview({ data, startStream, aiTalking, endStream, generalApplicationData }: Readonly<TProps>) {
  const [alertOpen, setAlertOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const playerRef = useRef<any>(null)
  const { webcamManager, stopRecording } = useVocalConversation({
    interviewId: data?.id,
  })

  // const { data: details } = useUserDetails()
  const finishInterviewMutation = useFinishInterviewMutation()
  const [started, setStarted] = useState(false)
  const [prepDone, setPrepDone] = useState(false)

  // const username = details?.firstName && details?.lastName ? `${details?.firstName || ""} ${details?.lastName || ""}` : (details?.email || "Candidate")
  const username = data?.userName || "Candidate"
  // const intials = details?.firstName && details?.lastName ? `${details?.firstName?.charAt(0) || ""}${details?.lastName?.charAt(0) || ""}` : (`${details?.email?.charAt(0) || ""}` || "C")
  const intials = data?.userName?.charAt(0) || "C"

  const { timeElapsed } = useInterviewTimeElapsedCounter({
    interviewId: Number(data?.id) || -1,
    start: started
  })


  const bgColor = data?.organization?.color || "#70e2ff"


  const avatarAnswerLoading = aiTalking === undefined
  const avatarTalking = aiTalking
  const listening = !aiTalking


  function getAvatarImageFromModel(model: string) {
    if (model === "shimmer") return AliceAvatar
    else if (model === "echo") return AlexAvatar
    else if (model === "onyx") return JamesAvatar
    else if (model === "nova") return EmmaAvatar
    else return AlexAvatar
  }

  async function onProceedHandler() {
    if (data?.id) {
      finishInterviewMutation({ interviewUUID: data.uuid })
    }
    endStream()
    stopRecording()
    webcamManager.shutdownCamera()
  }



  useEffect(() => {
    if (!containerRef.current) return
    if (!prepDone) return
    (async () => {
      containerRef.current?.requestFullscreen().then(() => {
        setStarted(true)
      }).catch((error) => {
        console.log('error', error)
      })
      // await startInterviewAndGreetUser()
    })()
  }, [prepDone])



  function getAvatarStatusMessage() {
    // if (status === "creating" || status === "greeting") return "Initiating"
    // else if (avatarAnswerLoading) return "Thinking"
    // else if (status === "started") return "Started"
    // else return "Ready"
    // TODO: Implement getAvatarStatusMessage()
    return ""
  }


  if (!prepDone) {
    return (
      <PreInterviewComponent
        onClickStart={() => {
          startStream()
          setPrepDone(true)
          webcamManager?.turnonCamera()
        }}
        webcamManager={webcamManager}
        data={data}
      // noMic={noMic}
      />
    )
  }


  return (
    <div ref={containerRef} className="" style={{
      // background: data?.organization?.color,
      background: getDominantColor(bgColor)
    }}>
      <audio ref={playerRef} id="player" controls className="hidden">
        Your browser does not support the audio element.
      </audio>
      <AlertModal
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title="You are about to finish this session"
        body={`Are you sure you want to finish this session? You will not be able to resume it later.`}
        confirmText="Finish"
        onProceed={onProceedHandler}
      />
      <div className='w-full overflow-hidden h-screen py-4 px-4 flex flex-col'>

        <div className='flex space-x-2 mb-2 h-full'>
          <div className={
            cn(
              "w-full h-full  flex items-center justify-center p-1 overflow-hidden relative rounded-xl ")
          }
          >
            <span
              style={{ background: bgColor }}
              className='block   w-full h-full rounded-lg flex items-center justify-center shadow'>
              <div className='absolute top-4 left-4 text-slate-100 flex items-center justify-between '>
                {data?.organization?.logoUrl && (
                  <div className="bg-gray-200 mr-2 rounded-full size-[25px]">
                    <img src={data?.organization?.logoUrl}
                      // className="object-fit"
                      alt="Company Logo"
                      className="object-cover  size-[25px] sm:size-[25px] rounded"
                    // style={{
                    //   width: "25px",
                    //   height: '25px',
                    //   // objectFit: "fill",
                    //   aspectRatio: 1
                    // }}
                    />
                  </div>
                )}
                <p
                  style={{
                    color: getContrastColorFromBackgroundColor(bgColor)
                  }}
                  className="text-gray-200 text-sm sm:text-xl">{data?.title}</p>
              </div>
              {avatarAnswerLoading && (
                <div
                  style={{
                    background: bgColor,
                    color: getContrastColorFromBackgroundColor(bgColor)

                  }}
                  className="flex space-x-2 absolute top-4 right-4 text-slate-100">
                  <p>{getAvatarStatusMessage()}</p>
                  <Spinner />
                </div>
              )}

              <div className="w-full  grid grid-cols-1 md:grid-cols-2 gap-10  px-2 sm:px-10 place-items-center">
                <div className={cn(
                  "w-full max-h-[40vh]  aspect-video ",
                  "overflow-hidden",
                  "shadow-xl  rounded-xl ",
                  "animate-border inline-block from-[#0558EE] via-[#ffffff] to-[#21E8E6] bg-[length:400%_400%]",
                  "w-full h-full bg-slate-900 flex items-center justify-center p-1 overflow-hidden relative rounded-xl ", avatarTalking ? "bg-gradient-to-r" : "border border-white border-2"
                )}>

                  <span className='block  bg-slate-900 w-full h-full rounded-lg flex items-center justify-center'>

                    <div className="h-full w-full flex items-center justify-center ">
                      <img
                        src={getAvatarImageFromModel(data?.voiceModel || generalApplicationData?.voiceModel || "echo")}
                        className="object-cover size-[140px] lg:size-[200px] rounded-full z-10"
                      />

                      {avatarTalking && (
                        <div className="absolute size-[180px] lg:size-[255px] z-0 grayscale">
                          <Lottie options={{
                            loop: true,
                            autoplay: true,
                            animationData: lottieData,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice'
                            }
                          }}
                            // width={200}
                            // isStopped={stopped}
                            isPaused={!avatarTalking}
                          />
                        </div>
                      )}


                    </div>


                    <div className="absolute left-0 bottom-0 text-gray-200">

                      <div className="absolute bottom-2 left-2 p-2 rounded-xl flex space-x-1 items-center"
                        style={{
                          background: bgColor,
                          color: getContrastColorFromBackgroundColor(bgColor)
                        }}
                      >
                        <VetonLogo className="size-3" />
                        <p className="text-xs">{getAvatarNameFromModel(data?.voiceModel || "echo")}</p>
                      </div>
                    </div>

                  </span>
                </div>

                <div className={cn(
                  // "w-[320px] h-[180px]",
                  "w-full max-h-[40vh] aspect-video relative",
                  "overflow-hidden",
                  // "border border-white border-2",
                  "flex items-center justify-center",
                  "shadow-xl  rounded-xl overflow-hidden",
                  "animate-border inline-block from-[#0558EE] via-[#ffffff] to-[#21E8E6] bg-[length:400%_400%]",
                  "w-full h-full bg-slate-900 flex items-center justify-center p-1 overflow-hidden relative rounded-xl ", listening ? "bg-gradient-to-r" : "border border-white border-2"
                )}
                >
                  <span className='block  bg-slate-900 w-full h-full rounded-lg flex items-center justify-center'>
                    {webcamManager?.userSetCamereOn && (
                      <>
                        <div className="z-10  size-full absolute size-full">
                          <Webcam
                            audio={false}
                            width={"100%"}
                            height={"100%"}
                            mirrored
                          />
                        </div>

                        {webcamManager.activeRecordings?.map((recording, idx) => (
                          <video
                            key={recording?.id || idx}
                            className='webcam size-full ' ref={recording?.webcamRef} loop autoPlay playsInline muted
                          />
                        ))}
                      </>
                    )}
                    {!webcamManager?.userSetCamereOn && (
                      <div className="size-[80px] sm:size-[180px] rounded-full bg-gray-100 flex items-center justify-center text-xl sm:text-4xl lg:text-7xl">
                        <p className="text-gray-500">{intials?.toUpperCase()}</p>
                      </div>
                    )}
                    <div className="absolute bottom-2 left-2 p-2 rounded-xl flex space-x-1 items-center z-10"
                      style={{
                        background: bgColor,
                        color: getContrastColorFromBackgroundColor(bgColor)
                      }}
                    >
                      <User2Icon className="size-3" />
                      <p className="text-xs">{username}</p>
                    </div>
                  </span>
                </div>
              </div>

            </span>
          </div>


        </div>
        <div className="w-full h-[60px]  rounded-xl flex items-center justify-center relative space-x-2 shadow"
          style={{
            background: (bgColor),

          }}
        >

          {started && (
            <div className="grid grid-cols-3  w-full ">
              <div className="flex flex-row w-full h-[40px] ml-4 text-[16px] items-center space-x-3 text-white ">

                <div className='flex flex-col justify-center items-center'>
                  <p className='font-bold'>{
                    `0${Math.floor(timeElapsed / 60)}`.slice(-2)
                  }</p>
                  <p className='text-[8px] opacity-50'>Minutes</p>
                </div>

                <p className='font-bold'>:</p>

                <div className='flex flex-col justify-center items-center'>
                  <p className='font-bold'>
                    {timeElapsed ? `0${timeElapsed % 60}`.slice(-2) : 0}
                  </p>
                  <p className='text-[8px] opacity-50'>Seconds</p>
                </div>
              </div>
              <div className="flex  w-full gap-2 items-center justify-center">
                <Button
                  className="w-full text-white size-[40x] hidden sm:block"
                  style={{
                    background: (bgColor),
                  }}
                  // disabled={!!noMic}
                  disabled
                  variant={"outline"}
                // onClick={
                //   () => {
                //     setMicOn(!micOn);
                //   }
                // }
                >
                  <Mic className="size-5" />
                </Button>
                <Button
                  className="w-full text-white size-[40x] hidden sm:block"
                  // disabled={webcamManager.noCameraError}
                  disabled
                  style={{
                    background: (bgColor),
                  }}
                  variant={"outline"}
                // onClick={async () => {
                //   webcamManager.setUserSetCameraOn(!webcamManager.userSetCamereOn);
                //   if (webcamManager.userSetCamereOn) {
                //     await webcamManager.shutdownCamera();
                //   } else {
                //     await webcamManager.turnonCamera();
                //   }
                // }}
                >
                  {webcamManager.userSetCamereOn ? <Video className="size-5" /> : <VideoOff className="size-5 text-rose-400" />}


                </Button>

                <Button
                  className="ml-12 sm:ml-0"
                  variant="destructive"
                  onClick={() => {
                    if (document.fullscreenElement) {
                      document.exitFullscreen().catch((error) => {
                        console.log("Full screen exit error", error)
                      })
                    }
                    setAlertOpen(true)
                  }}
                  disabled={status === "initial"}
                >
                  <LogOutIcon className="mr-1 size-4" /> FINISH <span className='hidden sm:block'>INTERVIEW</span>
                </Button>

              </div>
              <div className='w-full flex justify-end'>
                <SettingsDialog variant={"icon"} webcamManager={webcamManager} />
              </div>

            </div>
          )}


          <div className="absolute right-4">
            {/* something for the right side */}
            {/* <Button
              onClick={() => {
                webcamManager?.quickDemo()
              }}
            >CLICK ME</Button> */}
            {/* <webcamManager.SelectAudioDeviceInput /> */}
            {/* <webcamManager.SelectVideoDeviceInput /> */}
          </div>
        </div>
      </div>
    </div>
  )
}