import { InterviewPage } from '@/components/core/interview';
import { Loader } from '@/components/core/loader';
import { createFileRoute, ErrorComponent } from '@tanstack/react-router'



export const Route = createFileRoute('/interviews/$interviewId')({
  // beforeLoad: ({ context, location }) => {
  //   const isSignedIn = context?.auth?.isSignedIn;
  //   if (!isSignedIn) {
  //     throw redirect({
  //       to: '/signin',
  //       search: {
  //         redirect_url: location.href,
  //       },
  //     })
  //   }
  // },
  errorComponent: ({ error }) => {
    console.log("error @scenario page", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  },
  component: InterviewRoute,
})


function InterviewRoute() {
  return (
    <InterviewPage />
  )
}
