import { useRef, useCallback } from "react";

export function useMediaRecorder(
  audioFormat: "mp4" | "webm",
  mimeType: "audio/mp4" | "audio/webm"
) {
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<BlobPart[]>([]);
  const currentStream = useRef<MediaStream | null>(null);

  const cleanUpStream = () => {
    if (currentStream.current) {
      currentStream.current.getTracks().forEach((track) => track.stop());
      currentStream.current = null;
    }
  };

  const startRecording = useCallback(async () => {
    try {
      cleanUpStream(); // Clean up any existing stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      currentStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream, { mimeType });
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorder.current.start(1000); // Collect data every second
    } catch (error) {
      console.error("Error starting media recorder: ", error);
    }
  }, [mimeType]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current) {
      return new Promise((resolve) => {
        // @ts-ignore
        mediaRecorder.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: `audio/${audioFormat}`,
          });
          audioChunksRef.current = [];
          resolve(audioBlob);
        };
        // @ts-ignore
        mediaRecorder.current.stop();
        cleanUpStream(); // Clean up the stream after stopping
      });
    }
  }, [audioFormat]);

  const pause = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
    }
  }, []);

  const resume = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
    }
  }, []);

  return {
    startRecording,
    stopRecording,
    pause,
    resume,
    mediaRecorder,
    cleanUpStream,
  };
}
