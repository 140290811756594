import { TPosition, useHiringTeamMembers } from "@/fetchers/usePosition"

import { Separator } from "@/components/ui/separator"
import { Loader } from "../../loader"
import { HiringTeamForm } from "./hiring-team-form"
import { useIntercomMessenger } from "@/lib/useIntercomMessenger"
import { useGetTeamList } from "@/fetchers/useOrganization"

import { useParams } from "@tanstack/react-router"



export default function HiringTeamFormView({ position }: { position?: TPosition }) {
  const { showNewMessage } = useIntercomMessenger()
  // const { data: userDetails, isFetching: isFetchingUserDetails } = useUserDetails()

  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false })

  const { data, isFetching } = useHiringTeamMembers({
    slug: slug,
    organizationId: Number(organizationId)
  })
  const { data: teamMembers, isFetching: isFetchingTeamMembers } = useGetTeamList()

  const availableMembers = teamMembers?.filter(member => {
    return !data?.find(hiringTeamMember => hiringTeamMember.user.email === member.email)
  })


  const enrichedHiringTeam = data?.map(hiringTeamMember => ({
    ...hiringTeamMember,
    user: teamMembers?.find(member => member.email === hiringTeamMember.user.email) || hiringTeamMember.user
  }))




  if (isFetching || isFetchingTeamMembers) {
    return (
      <Loader />
    )
  }


  return (
    <div className="space-y-6  lg:max-w-3xl  pb-[120px]">
      <div>
        <h3 className="text-lg font-medium">Hiring Team</h3>
        <p className="text-sm text-muted-foreground">
          Manage the hiring team involved in this position. <button
            className="text-blue-500 hover:underline"
            onClick={() => {
              showNewMessage(
                "Hi! I would like to learn more about the hiring team feature."
              )
            }}>Learn More</button>
        </p>
      </div>
      <Separator />
      <HiringTeamForm
        position={position}
        availableMembers={availableMembers || []}
        // @ts-ignore
        hiringTeam={enrichedHiringTeam || []}
      />
    </div>
  )
}
