import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import { Button } from "@/components/ui/button"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { toast } from "sonner"
import { TApplicationForm } from "@/fetchers/usePosition"

import { useIsMutating } from "@tanstack/react-query"
import { useUpdateNotificationSettingsMutation } from "@/fetchers/useUserDetails"

// None // Good Fits Only // All
// Applying Candidates                               

// Resume Analysis Results

// Interview Started 

// Interview Completed

// Interview Analysis Result

const notificationFormSchema = z.object({
  applyingCandidates: z.enum(["none", "all"]),
  resumeAnalysisResults: z.enum(["none", "good_fits_only", "all"]),
  interviewStarted: z.enum(["none", "good_fits_only", "all"]),
  interviewAnalysisResults: z.enum(["none", "good_fits_only", "all"]),
})

type DisplayFormValues = z.infer<typeof notificationFormSchema>

// This can come from your database or API.
const defaultValues: Partial<DisplayFormValues> = {
  applyingCandidates: "none",
  resumeAnalysisResults: "none",
  interviewStarted: "none",
  interviewAnalysisResults: "none",
}


export function NotificationForm({ notificationForm }: { notificationForm?: TApplicationForm }) {
  // @ts-ignore
  // const { organizationId, slug } = useParams({ strict: false })
  const update = useUpdateNotificationSettingsMutation()
  const isMutating = useIsMutating()

  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(notificationFormSchema),
    defaultValues: {
      ...defaultValues,
      ...notificationForm,
    }
  })


  async function onSubmit(data: DisplayFormValues) {
    // @ts-ignore
    const { error } = await update({ data })
    if (error) {
      toast.error("Error updating settings")
      return
    }
    toast.success("Settings updated successfully")


  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">

        <FormField
          control={form.control}
          name="applyingCandidates"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Candidate Applied</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="none">None</TabsTrigger>
                      <TabsTrigger value="all">All</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />


        <FormField
          control={form.control}
          name="resumeAnalysisResults"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Resume Analyzed</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="none">None</TabsTrigger>
                      <TabsTrigger value="good_fits_only">Good Fits Only</TabsTrigger>
                      <TabsTrigger value="all">All</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="interviewStarted"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Interview Started</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="none">None</TabsTrigger>
                      <TabsTrigger value="good_fits_only">Good Fits Only</TabsTrigger>
                      <TabsTrigger value="all">All</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />



        <FormField
          control={form.control}
          name="interviewAnalysisResults"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full ">

                <FormLabel>Interview Completed</FormLabel>
                <FormControl>
                  <Tabs defaultValue={field.value} className="" onValueChange={field.onChange}>
                    <TabsList  >
                      <TabsTrigger value="none">None</TabsTrigger>
                      <TabsTrigger value="good_fits_only">Good Fits Only</TabsTrigger>
                      <TabsTrigger value="all">All</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </FormControl>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          isLoading={isMutating > 0}
          className="float-right mt-12" type="submit">Save Changes</Button>
      </form>
    </Form>
  )
}
