import { Separator } from "@/components/ui/separator"
import { DescriptionForm } from "./description-form"
import { TPosition } from "@/fetchers/usePosition"

export default function DescriptionFormView({ position }: { position?: TPosition }) {
  return (
    <div className="space-y-6  lg:max-w-2xl  pb-[120px]">
      <div>
        <h3 className="text-lg font-medium">Position Description</h3>
        <p className="text-sm text-muted-foreground">
          A compelling job description is key to attract suitable candidates.
        </p>
      </div>
      <Separator />
      <DescriptionForm position={position} />
    </div>
  )
}
