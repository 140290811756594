import { useRouteContext } from "@tanstack/react-router";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

export function useIntercomMessenger() {
  const {
    auth: { user },
  } = useRouteContext({ strict: false });

  const {
    boot,
    // shutdown,
    hardShutdown,
    update,
    // hide,
    show,
    // showMessages,
    // showNewMessage,
    getVisitorId,
    // showConversation,
    showNewMessage,
  } = useIntercom();

  useEffect(() => {
    if (user) {
      update({
        email: user?.primaryEmailAddress?.emailAddress,
        userId: user?.id,
        name: user?.fullName ?? "Guest",
      });
    } else {
      update({
        email: undefined,
        userId: undefined,
        name: "Guest",
      });
    }
  }, [user]);

  const openChat = () => {
    // check if intercom booted
    if (!getVisitorId()) {
      boot({
        email: user?.primaryEmailAddress?.emailAddress,
        userId: user?.id,
        name: user?.fullName ?? "Guest",
      });
    }

    show();
  };

  const closeChat = () => {
    // @ts-ignore
    hardShutdown();
  };

  return { openChat, closeChat, showNewMessage };
}
