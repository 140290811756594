import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import { Link, useRouterState } from "@tanstack/react-router"
import { TPosition } from "@/fetchers/usePosition"

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string
    title: string
  }[],
  position?: TPosition
}

export function SidebarNav({ className, items, position, ...props }: SidebarNavProps) {
  const router = useRouterState();
  const pathname = router.location.pathname

  const getIsDisabled = (item: { href: string, title: string }) => {
    if (item.href.includes("create")) {
      return !item.href.includes("details")
    } else if (!position?.content || position?.content?.length <= 11) {
      return !(item.href.includes("details") || item.href.includes("description"))
    } else {
      return false
    }
  }

  return (
    <nav
      className={cn(
        "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1 overflow-x-auto",
        className
      )}
      {...props}
    >
      {items.map((item) => (
        <Link
          key={item.href}
          disabled={item.href.includes("create")}
          // @ts-ignore
          to={item.href}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            pathname === item.href
              ? "bg-muted hover:bg-muted"
              : "hover:bg-transparent hover:underline",
            "justify-start",
            getIsDisabled(item) && "text-gray-400 pointer-events-none",
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  )
}
