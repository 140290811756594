import { Loader } from '@/components/core/loader';
import { createFileRoute, ErrorComponent } from '@tanstack/react-router'
import { DetailsFormView } from '@/components/core/position/details';
import PositionCreationLayout from '@/components/core/position/layout'



export const Route = createFileRoute('/organizations/$organizationId/positions/create')({
  errorComponent: ({ error }) => {
    console.log("error @positions page", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />
  },
  component: NewPositionView,
})


function NewPositionView() {
  const { organizationId } = Route.useParams()

  if (!organizationId) {
    return (
      <Loader />
    )
  }

  return (
    <PositionCreationLayout
      title="Create Position"
      subtitle='Manage your position creation process and application settings.'
      organizationId={Number(organizationId)}>
      <DetailsFormView />
    </PositionCreationLayout>
  )
}