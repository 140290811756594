import { Button } from "@/components/ui/button";
import { TPosition, useCommunicationForm, useUpdateCommunicationFormMutation, useUpdatePositionMutation } from "@/fetchers/usePosition";
import { useUserDetailStore } from "@/fetchers/useUserDetails";
import { useNavigate, useParams } from "@tanstack/react-router";
import { toast } from "sonner";
import { mergeDataWithDefaults } from "../communication/form-defaults";


type TProps = {
  position?: TPosition
}
export default function PreviewFormView({ position }: Readonly<TProps>) {
  // @ts-ignore
  const { slug, organizationId } = useParams({ strict: false })
  const updatePositionMutation = useUpdatePositionMutation()

  const { selectedOrganization } = useUserDetailStore()
  const navigate = useNavigate()

  const { data: commData } = useCommunicationForm({
    slug: position?.slug
  })
  const updateCommunicationForm = useUpdateCommunicationFormMutation()


  const canPublish = Boolean((position?.content?.length || 0) >= 11 && position?.slug)


  async function onPublishClickHandler() {
    try {
      if (!commData?.afterApplication || !commData?.afterInterview) {
        // TODO: Take this default communication data creation to server
        // Currently it is here because the default is on client side
        const mergedCommData = mergeDataWithDefaults(commData)
        await updateCommunicationForm({
          data: {
            selectedMethod: mergedCommData.selectedMethod,
            afterApplication: mergedCommData.afterApplication,
            afterInterview: mergedCommData.afterInterview,
            autoInterview: mergedCommData.autoInterview,
            interviewDelay: mergedCommData.autoInterview ? mergedCommData.interviewDelay : "no_delay",
            interviewType: mergedCommData.interviewType,
            disqualified: mergedCommData.disqualifiedToggle === "disabled" ? undefined : mergedCommData.disqualified,
          },
          organizationId,
          slug
        })
      }
      await updatePositionMutation({
        data: {
          isOpen: !position?.isOpen
        },
        organizationId: Number(organizationId),
        slug
      })

      toast.success("Position published successfully")

      navigate({
        to: `/organizations/$organizationId/positions/list`,
        params: { organizationId }
      })
    } catch {
      toast.error("Failed to publish position")
    }
  }


  return (
    <div className="space-y-6  lg:max-w-2xl pb-[120px]">
      <div className="mb-12">
        <h3 className="text-lg font-medium">Preview and Publish</h3>
        <p className="text-sm text-muted-foreground">
          Setup company details for your organization and position.
        </p>
      </div>
      <div className="h-[65vh] w-[50vw] rounded overflow-hidden shadow-xl max-w-[700px] ">
        <iframe
          src={`${window.location.origin}/careers/${selectedOrganization?.slug}/${slug}?preview=true`}
          // src={`http://localhost:3000/careers/${selectedOrganization?.slug}/${slug}`}
          className=" border-0 transform scale-50  h-[130vh] origin-top-left	w-[100vw] max-w-[1400px] pointer-events-none"
        />

      </div>

      <Button
        disabled={!canPublish}
        className="float-right" onClick={onPublishClickHandler}>{position?.isOpen ? "Close Position" : "Publish"}</Button>
    </div>
  )
}
