import { createFileRoute } from '@tanstack/react-router'
import DashboardPage from '@/pages/dashboard/page';
import { Notifications } from '@/components/core/notifications';

export const Route = createFileRoute('/user/notifications')({
  component: UserNotificationPage
})

function UserNotificationPage() {
  return (
    <DashboardPage>
      <div>
        <Notifications />
      </div>
    </DashboardPage>

  )
}




// None // Good Fits Only // All
// Applying Candidates                               

// Resume Analysis Results

// Interview Started 

// Interview Completed

// Interview Analysis Result
