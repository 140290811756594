import ashby_big from "@/assets/images/ats/big/ashby_big.png";
import bamboo_hr_big from "@/assets/images/ats/big/bamboo_hr_big.png";
import breezy_big from "@/assets/images/ats/big/breezy_big.png";
import greenhouse_big from "@/assets/images/ats/big/greenhouse_big.png";
import jazzhr_big from "@/assets/images/ats/big/jazzhr_big.png";
// import jobvite_big from "@/assets/images/ats/big/jobvite_big.png";
import lever_big from "@/assets/images/ats/big/lever_big.png";
import xml_big from "@/assets/images/ats/big/xml_big.png";
import workable_big from "@/assets/images/ats/big/workable_big.png";
import recruiterflow_big from "@/assets/images/ats/big/recruiterflow_big.png";
import zoho_big from "@/assets/images/ats/big/zoho_big.webp";
import crelate_big from "@/assets/images/ats/big/crelate_big.svg";

import ashby_small from "@/assets/images/ats/small/ashby_small.png";
import crelate_small from "@/assets/images/ats/small/crelate_small.jpeg";
import bamboo_hr_small from "@/assets/images/ats/small/bamboo_hr_small.jpg";
import breezy_small from "@/assets/images/ats/small/breezy_small.jpg";
import greenhouse_small from "@/assets/images/ats/small/greenhouse_small.jpg";
import jazzhr_small from "@/assets/images/ats/small/jazzhr_small.jpg";
// import jobvite_small from "@/assets/images/ats/small/jobvite_small.png";
import lever_small from "@/assets/images/ats/small/lever_small.jpg";
import xml_small from "@/assets/images/ats/small/xml_small.png";
import workable_small from "@/assets/images/ats/small/workable_small.jpg";
import recruiterflow_small from "@/assets/images/ats/small/recruiterflow_small.png";
import zoho_small from "@/assets/images/ats/small/zoho_small.webp";
import threedots from "@/assets/images/ats/small/threedots.svg";

export const typeValues = [
  { value: "Full-time", label: "Full-time" },
  { value: "Part-time", label: "Part-time" },
  { value: "Contract", label: "Contract" },
  { value: "Internship", label: "Internship" },
  { value: "Freelance", label: "Freelance" },
  { value: "Temporary", label: "Temporary" },
  { value: "Other", label: "Other" },
];

export const educationLevelValues = [
  { value: "Unspecified", label: "Unspecified" },
  { value: "Any", label: "Any" },
  { value: "High School or equivalent", label: "High School or equivalent" },
  { value: "Certification", label: "Certification" },
  { value: "Vocational", label: "Vocational" },
  { value: "Associate Degree", label: "Associate Degree" },
  { value: "Bachelor's Degree", label: "Bachelor's Degree" },
  { value: "Master's Degree", label: "Master's Degree" },
  { value: "Doctorate", label: "Doctorate" },
  { value: "Professional", label: "Professional" },
];

export const experienceLevelValues = [
  { value: "Not applicable", label: "Not applicable" },
  { value: "Internship", label: "Internship" },
  { value: "Entry level", label: "Entry level" },
  { value: "Associate", label: "Associate" },
  { value: "Mid level", label: "Mid level" },
  { value: "Senior level", label: "Senior level" },
  { value: "Director", label: "Director" },
  { value: "Executive", label: "Executive" },
];

export const interviewVerdictValues = {
  not_applicable: "Completed",
  not_fit: "Not Fit",
  maybe_fit: "Maybe Fit",
  good_fit: "Good Fit",
  great_fit: "Great Fit",
  disqualified: "Disqualified",
};

export const interviewFilterValues = {
  not_applicable: {
    label: "Completed",
    selected: false,
  },
  not_fit: {
    label: "Not Fit",
    selected: false,
  },
  maybe_fit: {
    label: "Maybe Fit",
    selected: false,
  },
  good_fit: {
    label: "Good Fit",
    selected: false,
  },
  great_fit: {
    label: "Great Fit",
    selected: false,
  },
  disqualified: {
    label: "Disqualified",
    selected: false,
  },
};

export const pipelineStages = {
  applied: "applied",
  interviewSent: "Interview_sent",
  interviewCompleted: "Interview_completed",
  disqualified: "disqualified",
};

export const resumeVerdictValues = {
  interview_recommended: "Good Fit",
  interview_possibly_recommended: "Maybe Fit",
  interview_not_recommended: "Not Fit",
  resume_not_provided: "Resume Not Provided",
};

export const resumeFilterValues = {
  interview_recommended: {
    label: "Good Fit",
    selected: false,
  },
  interview_possibly_recommended: {
    label: "Maybe Fit",
    selected: false,
  },
  interview_not_recommended: {
    label: "Not Fit",
    selected: false,
  },
  resume_not_provided: {
    label: "Resume Not Provided",
    selected: false,
  },
};

export const disqualifiedFilterValues = {
  archived: {
    label: "Archived",
    selected: false,
  },
};

export const AI_VOICES = {
  avatarAlex:
    "https://veton-bucket.nyc3.cdn.digitaloceanspaces.com/veton-bucket/defaults/avatar_alex.mp3",

  avatarAlice:
    "https://veton-bucket.nyc3.cdn.digitaloceanspaces.com/veton-bucket/defaults/female_alice.mp3",

  avatarJames:
    "https://veton-bucket.nyc3.cdn.digitaloceanspaces.com/veton-bucket/defaults/avatar_james.mp3",

  avatarEmma:
    "https://veton-bucket.nyc3.cdn.digitaloceanspaces.com/veton-bucket/defaults/avatar_emma_1.mp3",
};

export const CONNECTIONS = [
  {
    id: "WORKABLE",
    name: "Workable ATS",
    authMethod: "API_KEY",
    active: true,
    imageUrl: workable_small,
    bigImageUrl: workable_big,
  },
  {
    id: "Lever",
    name: "Lever ATS",
    authMethod: "API_KEY",
    active: true,
    imageUrl: lever_small,
    bigImageUrl: lever_big,
  },
  {
    id: "ZOHO_RECRUIT",
    name: "Zoho Recruit ATS",
    authMethod: "OAUTH2",
    active: true,
    imageUrl: zoho_small,
    bigImageUrl: zoho_big,
  },
  {
    id: "BreezyATS",
    name: "Breezy ATS",
    authMethod: "USERNAME_PASSWORD",
    active: true,
    imageUrl: breezy_small,
    bigImageUrl: breezy_big,
  },
  {
    id: "JAZZ_HR",
    name: "JazzHR ATS",
    authMethod: "API_KEY",
    active: true,
    imageUrl: jazzhr_small,
    bigImageUrl: jazzhr_big,
  },
  {
    id: "RECRUITER_FLOW",
    name: "RecruiterFlow ATS",
    authMethod: "API_KEY",
    active: true,
    imageUrl: recruiterflow_small,
    bigImageUrl: recruiterflow_big,
  },
  // {
  //   id: "jobvite",
  //   name: "Jobvite ATS",
  //   active: false,
  //   imageUrl: jobvite_small,
  //   bigImageUrl: jobvite_big,
  // },
  {
    id: "greenhouse",
    name: "Greenhouse ATS",
    active: false,
    imageUrl: greenhouse_small,
    bigImageUrl: greenhouse_big,
  },
  {
    id: "bamboo_hr",
    name: "BambooHR ATS",
    active: false,
    imageUrl: bamboo_hr_small,
    bigImageUrl: bamboo_hr_big,
  },
  {
    id: "ashby",
    name: "Ashby ATS",
    active: false,
    imageUrl: ashby_small,
    bigImageUrl: ashby_big,
  },

  {
    id: "CRELATE",
    name: "Crelate ATS",
    active: true,
    imageUrl: crelate_small,
    bigImageUrl: crelate_big,
  },

  {
    id: "XML_FEED",
    name: "XML Feed",
    authMethod: "XML_URL",
    active: true,
    imageUrl: xml_small,
    bigImageUrl: xml_big,
  },
  {
    id: "more",
    name: "Others",
    active: false,
    imageUrl: threedots,
    // bigImageUrl: sage_big,
  },
];
