
import { CareersView } from '@/components/core/careers';
import { Loader } from '@/components/core/loader';
import { useGetPublicOrganization } from '@/fetchers/useOrganization';
import { getOrganizationSlugIfSubdomain } from '@/lib/utils';
import { ErrorComponent, Navigate, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: Index,
  errorComponent: ({ error }) => {
    console.log("error @index", error)
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return (
      <div className='w-screen h-screen'>
        <Loader />
      </div>
    )
  },
  // loader:  () => {

  // }

})

function Index() {
  // const isCareersSubdomain = window.location.hostname.split('.')?.[1] === "careers"
  // const organizationName = window.location.hostname.split('.')[0]

  const organizationSlug = getOrganizationSlugIfSubdomain()

  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug
  })

  if (isFetching) {
    return (
      <Loader />
    )
  }

  if (organization) {
    return (
      <CareersView />
    )
  }

  return <Navigate to='/organizations' />

  // return (
  //   <div className="p-2">
  //     <p>Redirecting...</p>
  //   </div>
  // )
}