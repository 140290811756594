import { createFileRoute } from '@tanstack/react-router'

import { z } from 'zod'

import { ConfirmationPage } from '@/components/core/interview-confirmation'
import { useEffect } from 'react'



const confirmationSearchSchema = z.object({
  phone: z.union([z.string(), z.number()]),
  redirect: z.string().optional(),
})

// type TConfirmation = z.infer<typeof confirmationSearchSchema>


export const Route = createFileRoute('/interviews/$interviewUid/confirmation')({
  validateSearch: search => confirmationSearchSchema.parse(search),
  component: ConfirmationPageRoute,
})

function ConfirmationPageRoute() {
  const { redirect } = Route.useSearch()
  const { auth: { signOut } } = Route.useRouteContext()

  useEffect(() => {
    if (!redirect) return

    signOut()
      .then(() => {
        if (!redirect) return
        window.location.href = redirect
      })


  }, [redirect])


  if (redirect) {
    return null
  }

  return (
    <ConfirmationPage />
  )
}
