import { Button } from "@/components/ui/button"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { useState } from "react"


import { DownloadIcon } from "lucide-react";
import { useImportApplicant } from "@/lib/useImportApplicant";
import { MIME_TYPE, UploadInput } from "../common/UploadInput";
import { useIsMutating } from "@tanstack/react-query";



export function ImportApplicantDialog({ onSelectFile }: { onSelectFile: (file: File) => void }) {
  const [open, setOpen] = useState(false);
  const isMutating = useIsMutating()
  const { downloadTemplate } = useImportApplicant()

  // @ts-ignore
  // const { slug } = useParams({ strict: false })

  // const { data: position, isFetching: isFetchingPosition } = useGetPublicPosition({ slug })



  // if (isFetchingPosition) {
  //   return null
  // }


  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button
          className='h-[40px]'
          variant="outline"
        // onClick={() => navigate({
        //   to: `/organizations/$organizationId/positions/create`,
        //   params: { organizationId: String(organizationId) }
        // })}
        >
          <DownloadIcon className='size-4 mr-1' />
          Import
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[70vh] overflow-y-auto">

        <DialogHeader>
          <DialogTitle>Import Applicants</DialogTitle>
          <DialogDescription>
            Import applicants from a spreadsheet
          </DialogDescription>
        </DialogHeader>


        <div className="space-y-12  w-full pt-6">

          <div className="flex items-center gap-3 ">
            <NumberDisplay>1</NumberDisplay>
            <div className="flex flex-col">
              <p className="text-sm">Download the Applicant template</p>
              <div>
                <Button onClick={downloadTemplate}
                  size={'sm'}
                  className="mt-1"
                >
                  <DownloadIcon className='size-4 mr-1' />
                  Download
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <NumberDisplay>2</NumberDisplay>
            <div className="flex flex-col">
              <p className="text-sm font-bold">Add Content</p>
              <div>
                <p className="text-sm">Don't delete or edit columns</p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3 w-full">
            <NumberDisplay>3</NumberDisplay>
            <div className="flex flex-col w-full">
              <p className="text-sm font-bold">Upload your file</p>
              <div className="w-full">
                <UploadInput
                  mimeType={MIME_TYPE.EXCEL}
                  onChange={async (event) => {
                    const file = event.target.files?.[0]

                    if (!file) {
                      console.log('no file')
                      return
                    }
                    await onSelectFile(file)
                    setOpen(false)

                  }}
                />
              </div>
            </div>
          </div>


        </div>

        <DialogFooter>

          <Button
            isLoading={isMutating > 0}
            variant={'outline'}
            onClick={() => setOpen(false)}
          >Close</Button>
        </DialogFooter>


      </DialogContent>
    </Dialog>
  )
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NumberDisplay({ children }: any) {
  return (
    <div>
      <div className="size-10 bg-gray-200 rounded-full text-xs font-bold flex items-center justify-center text-gray-900">
        {children}
      </div>
    </div>
  )
}


