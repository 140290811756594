import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { useAudioStream } from '@/lib/useAudioStream';

export const Route = createFileRoute('/audio-stream')({
  component: AudioStream,
});

function AudioStream(): JSX.Element {
  const { data, isStarted, setIsStarted } = useAudioStream({});



  return (
    <div className='flex items-center flex-col justify-center pt-20 space-y-4'>
      <h2 className='text-2xl'>General Application Sample Interview</h2>
      {isStarted ? (
        <p>Interviewing with {data?.voiceModel} voice model</p>
      ) : (
        <Button onClick={() => setIsStarted(true)}>Start Interview</Button>
      )}
      <audio controls className='hidden'></audio>
    </div >
  );
}