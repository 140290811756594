import type { ComponentProps } from "react";
import ReactQuill from "react-quill";
import { forwardRef } from 'react';
import "react-quill/dist/quill.snow.css";

type ReactQuillProps = ComponentProps<typeof ReactQuill>;
type Props = Pick<
  ReactQuillProps,
  // @ts-expect-error fix later
  "value" | "onChange" | "name" | "placeholder" | "theme"
>;

const toolBarOptions = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};






const TextEditor = forwardRef(function TextEditor(props: Props, ref: React.Ref<ReactQuill>) {
  // useEffect(() => {
  //   const ColorStyle = ReactQuill.Quill.import("attributors/style/color");
  //   const ColorClass = ReactQuill.Quill.import("attributors/class/color");
  //   const BackgroundStyle = ReactQuill.Quill.import(
  //     "attributors/style/background"
  //   );

  //   ReactQuill.Quill.register(ColorStyle, false);
  //   ReactQuill.Quill.register(ColorClass, false);
  //   ReactQuill.Quill.register(BackgroundStyle, false);
  // }, [])

  return (<ReactQuill {...props} modules={toolBarOptions} ref={ref}>
  </ReactQuill>);
})

export { TextEditor }


