import { cn } from "@/lib/utils"
import { ComponentProps } from "react"
import { formatDistanceToNow } from "date-fns"
import { Badge } from "@/components/ui/badge"
import { ItemActions } from "./item-actions"
import { useDraggable } from "@dnd-kit/core"


type TProps = {
  id: number,
  draggable?: boolean
  title: string
  titleJSX?: () => JSX.Element
  completedAt?: string | null
  subtitle: string
  tags: string[]
  onClick: () => void
  content?: string | null
  // handleOnEdit?: () => void
  // handleTogglePublishPosition?: () => void
  isOpen: boolean,
  disabled?: boolean
  // displayActions: boolean,
  dropdownActions?: {
    title: string
    onClick: () => void
    customClasses?: string
  }[],
  applicantCount?: number
}

export function ItemPreview({
  id,
  draggable = false,
  title = '',
  completedAt,
  subtitle = '',
  tags = [],
  titleJSX,
  onClick,
  content = '',
  isOpen = true,
  // handleOnEdit,
  // handleTogglePublishPosition,
  // displayActions = false
  dropdownActions = [],
  disabled = false,
  applicantCount = 0,
}: Readonly<TProps>) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    disabled: !draggable,
  });
  const style = (draggable && transform) ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  const stripHtmlTags = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.innerText;
  };



  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div className={cn("relative w-full", disabled && "pointer-events-none")}>
        {dropdownActions.length > 0 && (
          <div className="absolute top-0 right-0 flex items-center ">
            <div
              className={cn(
                "ml-auto text-xs",
                // mail.selected === item.id
                //   ? "text-foreground"
                //   : "text-muted-foreground"
              )}
            >
              {(completedAt && dropdownActions.length === 0) && formatDistanceToNow(new Date(completedAt), {
                addSuffix: true,
              })}
            </div>
            <ItemActions
              // isOpen={isOpen}
              // handleOnEdit={handleOnEdit}
              // handleTogglePublishPosition={handleTogglePublishPosition}
              dropdownActions={dropdownActions}
            />
          </div>
        )}


        <button
          className={cn(
            "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent w-full",
            // mail.selected === interview.id && "bg-muted"
          )}
          onClick={onClick}
        // onClick={() =>
        //   setMail({
        //     ...mail,
        //     selected: interview,
        //   })
        // }
        >
          <div className="flex w-full flex-col gap-1 pr-10">
            <div className="flex items-center">
              <div className="flex items-center gap-2  w-full">
                <div className="font-semibold w-full ">{titleJSX ? titleJSX() : title} {applicantCount > 0 && (
                  <span className="text-xs text-gray-600 font-normal">
                    {" | "}{applicantCount} applicant{applicantCount > 1 ? "s" : ""}
                  </span>
                )}</div>
                {!isOpen && (
                  <Badge className="self-start -mt-1">
                    Closed
                  </Badge>
                )}
                {/* {!completedAt && (
              <span className="flex h-2 w-2 rounded-full bg-blue-600" />
            )} */}
              </div>
              <div
                className={cn(
                  "ml-auto text-xs",
                  (!completedAt || dropdownActions.length > 0) && "hidden"
                  // mail.selected === item.id
                  //   ? "text-foreground"
                  //   : "text-muted-foreground"
                )}
              >
                {completedAt && formatDistanceToNow(new Date(completedAt), {
                  addSuffix: true,
                })}
              </div>
            </div>
            <div className="text-xs font-medium">{subtitle}</div>
          </div>
          <div className="line-clamp-3 text-xs text-muted-foreground">
            {/* {content?.substring(0, 300)} */}
            {/* <span dangerouslySetInnerHTML={{ __html: content || "" }} /> */}
            <span>{stripHtmlTags(content || "")}</span>
          </div>
          {tags.length ? (
            <div className="flex items-center gap-2">
              {tags.map((tag) => (
                <Badge key={tag} variant={getBadgeVariantFromLabel(tag)}>
                  {tag}
                </Badge>
              ))}


            </div>
          ) : null}
        </button>
      </div>
    </div>
  )
}

function getBadgeVariantFromLabel(
  label: string
): ComponentProps<typeof Badge>["variant"] {
  if (["work"].includes(label.toLowerCase())) {
    return "default"
  }

  if (["personal"].includes(label.toLowerCase())) {
    return "outline"
  }

  return "secondary"
}


